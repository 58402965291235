import { darken } from "polished";
import styled from "styled-components";
import { Theme } from "styles/theme";

export const Address = styled.button`
  padding: 1.6rem 0 1.6rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  background-color: transparent;
  cursor: pointer;

  p {
    font-family: "Quicksand";
    font-size: 1.5rem;
    color: ${Theme.colors.gray};
    transition: color 0.2s;

    strong {
      font-family: "Nunito";
      font-size: 1.6rem;
      font-weight: bold;
      color: ${Theme.colors.tundora};
      margin-right: 1.2rem;
    }
  }

  &:hover {
    p {
      color: ${darken(0.1, Theme.colors.gray)};
    }
  }
`;
export const AddressImage = styled.div`
  display: flex;
  align-items: center;
`;

export const RightIcon = styled.img`
  margin-left: 1.6rem;
  height: 1.6rem;
`;
