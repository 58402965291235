import { getCode } from "country-list";
import creditCardType from "credit-card-type";
import { CreditCardTypeCardBrandId } from "credit-card-type/src/types";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import axios, { AxiosError } from "axios";
import { useMedia } from "react-use";

import TextField from "components/TextField";
import { useAuth } from "contexts/AuthUserContext";
import useCart from "hooks/useCart";
import api from "services/api";
import { CardInfoType, TokenizationCardType } from "./interface";
import { CardType } from "components/CardList/interface";
import Toast from "components/Toast";
import { Modal } from "components/Modal";
import useModal from "hooks/useModal";

import * as C from "../Cart/styles";
import * as S from "./styles";

type Tokenization3dsCaymanGatewayResponse = {
  consumerUrl: string;
  createdAt: string;
  expiresAt: string;
  id: string;
  status: string;
  vaultId: string | null;
};

type FormCardProps = {
  hasToFetchSettings: boolean;
  handleCloseNewCard?: Dispatch<SetStateAction<boolean>>;
  handleOpenCards?: Dispatch<SetStateAction<boolean>>;
  isOpened?: boolean;
};

const FormCard = ({
  handleCloseNewCard,
  handleOpenCards,
  isOpened,
}: FormCardProps) => {
  const [cardNumber, setCardNumber] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [expirationDateError, setExpirationDateError] = useState("");
  const [cvv, setCvv] = useState("");
  const [name, setName] = useState("");
  const [streetName, setStreetName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [cardInfo, setCardInfo] = useState<CardInfoType | null>(null);
  const [hasBillingError, setHasBillingError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");
  const [tokenizationId, setTokenizationId] = useState("");
  const { open: openModal, toggle: setOpenModal } = useModal();
  const { authUser } = useAuth();
  const { setCards, setCart, bentoSettings } = useCart();
  const isDesk = useMedia("(min-width: 768px)");

  const isCardAlter =
    cardInfo?.type === "american-express" || cardInfo?.type === "diners-club";
  const isTypeInRange = (type: CreditCardTypeCardBrandId) =>
    type === "visa" ||
    type === "mastercard" ||
    type === "discover" ||
    type === "american-express" ||
    type === "diners-club";
  const allFieldsFill = cardNumber && expirationDate && cvv && name;

  const clearForm = useCallback(() => {
    setCardNumber("");
    setExpirationDate("");
    setExpirationDateError("");
    setCvv("");
    setName("");
    setStreetName("");
    setCity("");
    setState("");
    setZip("");
    setCountry("");
  }, []);

  const getCardData = useCallback(
    (value: string) => {
      const infos = creditCardType(value);
      const info = (infos[0] as CardInfoType) || null;
      setCardInfo(info);
      setCvv("");
      setCardNumber(value);
    },
    [creditCardType]
  );

  const handleExpiration = useCallback((value: string) => {
    if (value.length === 1) {
      value = Number(value) > 1 ? "0" : value;
    }
    if (value.length === 2) {
      value = Number(value) > 12 || value === "00" ? "12" : value;
    }
    if (value.length === 7) {
      setExpirationDateError("");
    }
    setExpirationDate(value);
  }, []);

  const handleSave = useCallback(async () => {
    try {
      setIsLoading(true);

      if (expirationDate.length < 7) {
        setExpirationDateError("Input a valid date [MM/YYYY] format");
        setIsLoading(false);
        return;
      }

      setExpirationDateError("");

      const [expMonth, expYear] = expirationDate.split("/");
      const countrySave = getCode(country);

      const dataToSave: TokenizationCardType = {
        card: {
          number: cardNumber.replaceAll(" ", ""),
          expiration: `${expMonth}-${expYear}`,
          cvv,
        },
        billing: {
          name,
          address: !streetName ? "George Town, Cayman Islands" : streetName,
          city: !city ? "Grand Cayman" : city,
          state: !state ? "Cayman Islands" : state,
          postalCode: !zip ? "00000" : zip,
          country: countrySave || "KY",
        },
        defaultMethod: true,
      };

      if (
        bentoSettings &&
        bentoSettings.gateway === "caymangateway" &&
        bentoSettings.is3DsEnabled
      ) {
        const { data } = await axios.post(
          `${process.env.NEXT_PUBLIC_SERVICES_API_URL}/tokenization/caymangateway/3ds`,
          {
            billing: { ...dataToSave.billing },
            defaultMethod: true,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: api.defaults.headers.Authorization,
            },
          }
        );

        const number = cardNumber.replaceAll(" ", "");
        const [month, fullYear] = dataToSave.card.expiration.split("-");
        const year = fullYear.slice(-2);
        const cardExpiry = `${month}/${year}`;
        const cardCvv = cvv;

        const url = `/machine-3ds?cardNumber=${number}&cardExpiry=${cardExpiry}&cardCvv=${cardCvv}&consumerUrl=${data.consumerUrl}`;

        setIframeSrc(url);
        setTokenizationId(data.id);
        setOpenModal(true);
        return;
      }

      await axios.post(
        `${process.env.NEXT_PUBLIC_SERVICES_API_URL}/tokenization`,
        { ...dataToSave },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: api.defaults.headers.Authorization,
          },
        }
      );

      handleOpenCards && handleOpenCards(false);
      handleCloseNewCard && handleCloseNewCard(false);

      const { data: cardData } = await api.get<CardType[]>(
        `/users/${authUser?.uid}/payments`
      );

      const defaultCard =
        cardData.find((card) => card.defaultMethod) || cardData[0];

      setCards(cardData);
      clearForm();
      setCart((old) => ({ ...old, paymentMethod: defaultCard }));
    } catch (error) {
      const { response } = error as AxiosError;

      const message =
        (response?.data as Record<string, string>)?.message ||
        "Ops, something went wrong.";

      Toast({
        title: "Problems saving card",
        message: !hasBillingError
          ? "Some card issuers might ask for billing address"
          : message,
      });

      setHasBillingError(true);
    } finally {
      setIsLoading(false);
    }
  }, [
    cardNumber,
    expirationDate,
    cvv,
    name,
    streetName,
    city,
    state,
    zip,
    country,
    authUser,
  ]);

  async function get3dsReturn(interval: NodeJS.Timeout) {
    try {
      const { data } = await axios.get<Tokenization3dsCaymanGatewayResponse>(
        `${process.env.NEXT_PUBLIC_SERVICES_API_URL}/tokenization/caymangateway/3ds/${tokenizationId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: api.defaults.headers.Authorization,
          },
        }
      );

      if (data.status === "completed") {
        clearInterval(interval);

        handleOpenCards && handleOpenCards(false);
        handleCloseNewCard && handleCloseNewCard(false);

        const { data: cardData } = await api.get<CardType[]>(
          `/users/${authUser?.uid}/payments`
        );

        const defaultCard =
          cardData.find((card) => card.defaultMethod) || cardData[0];

        setIsLoading(false);
        setOpenModal(false);
        setIframeSrc("");
        setCards(cardData);
        clearForm();
        setCart((old) => ({ ...old, paymentMethod: defaultCard }));
      }

      if (data.status === "failed") {
        console.error("3DS failed to complete");
        clearInterval(interval);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      clearInterval(interval);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (isOpened && !isLoading) {
      window.document.getElementById("card_number")?.focus();
    }
  }, [isOpened, isLoading]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (tokenizationId) {
        get3dsReturn(interval);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [tokenizationId]);

  const renderCaymanGateway3dsModal = () => {
    return (
      <Modal
        title=""
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setIframeSrc("");
          setIsLoading(false);
        }}
        closeOnOverlayClick={false}
        style={
          isDesk
            ? { width: "70rem", overflow: "hidden" }
            : { overflow: "hidden" }
        }
      >
        <iframe
          src={iframeSrc}
          style={{ width: "100%", height: "100%", border: "0px" }}
        />
      </Modal>
    );
  };

  return (
    <>
      <S.FormCard>
        <S.FormRow>
          <TextField
            variant="material"
            type="text"
            name="cardNumber"
            label="Card number"
            labelFor="cardNumber"
            id="card_number"
            placeholder="0000 0000 0000 0000"
            value={cardNumber}
            disabled={isLoading}
            mask={isCardAlter ? "creditCardAlter" : "creditCard"}
            icon={
              <img
                src={
                  cardInfo?.type && isTypeInRange(cardInfo.type)
                    ? `/img/ic_card_${cardInfo?.type}.webp`
                    : "/img/icons-card.svg"
                }
                style={{
                  width: "3.8rem",
                  height: "2.4rem",
                  position: "absolute",
                  right: "0",
                }}
              />
            }
            iconPosition="right"
            onInput={(v) => getCardData(v as string)}
          />
        </S.FormRow>

        <S.FormRow>
          <S.FormGrid>
            <TextField
              variant="material"
              type="text"
              name="expirationDate"
              label="Expiration date"
              labelFor="expirationDate"
              id="card_exp"
              placeholder="MM/YYYY"
              value={expirationDate}
              disabled={isLoading}
              mask="dateMonthYear"
              error={expirationDateError}
              onInput={(v) => handleExpiration(v as string)}
            />
            <TextField
              variant="material"
              type="text"
              name="cvv"
              label="CVV"
              labelFor="cvv"
              id="card_cvv"
              placeholder={
                cardInfo?.type === "american-express" ? "0000" : "000"
              }
              value={cvv.replace(",", "")}
              disabled={isLoading}
              maxLength={cardInfo?.code.size || 3}
              mask="numberClean"
              onInput={(v) => setCvv(v as string)}
            />
          </S.FormGrid>
        </S.FormRow>

        <S.FormRow>
          <TextField
            variant="material"
            type="text"
            name="name"
            label="Name"
            labelFor="name"
            id="name"
            value={name}
            disabled={isLoading}
            onInput={(v) => setName(v as string)}
          />
        </S.FormRow>
      </S.FormCard>

      {hasBillingError && (
        <>
          <C.PaymentTitle>Billing Address</C.PaymentTitle>

          <S.FormCard>
            <S.FormRow>
              <TextField
                variant="material"
                type="text"
                name="streetName"
                label="Street Name"
                labelFor="streetName"
                id="streetName"
                placeholder="Sarah Lan Av., 832"
                value={streetName}
                onInput={(v) => setStreetName(v as string)}
              />
            </S.FormRow>

            <S.FormRow>
              <S.FormGrid>
                <TextField
                  variant="material"
                  type="text"
                  name="city"
                  label="City"
                  labelFor="city"
                  id="city"
                  value={city}
                  onInput={(v) => setCity(v as string)}
                />
                <TextField
                  variant="material"
                  type="text"
                  name="state"
                  label="State"
                  labelFor="state"
                  id="state"
                  value={state}
                  onInput={(v) => setState(v as string)}
                />
              </S.FormGrid>
            </S.FormRow>

            <S.FormRow>
              <S.FormGrid>
                <TextField
                  variant="material"
                  type="text"
                  name="zip"
                  label="Zip"
                  labelFor="zip"
                  id="zip"
                  value={zip}
                  placeholder="9322-002"
                  onInput={(v) => setZip(v as string)}
                />
                <TextField
                  variant="material"
                  type="text"
                  name="country"
                  label="Country"
                  labelFor="country"
                  id="country"
                  value={country}
                  onInput={(v) => setCountry(v as string)}
                />
              </S.FormGrid>
            </S.FormRow>
          </S.FormCard>
        </>
      )}

      <C.ButtonWrapper>
        <S.ButtonSave
          disabled={isLoading || !allFieldsFill}
          onClick={handleSave}
          loading={isLoading}
        >
          Save
        </S.ButtonSave>
      </C.ButtonWrapper>

      {openModal && iframeSrc && renderCaymanGateway3dsModal()}
    </>
  );
};

export default FormCard;
