import styled from "styled-components";
import { Theme } from "styles/theme";

export const Wrapper = styled.div`
  margin-top: 24px;
  margin-left: -16px;
  width: calc(100% + 32px);

  @media (min-width: 768px) {
    margin-left: -24px;
  }
`;

export const Title = styled.div`
  font-weight: 900;
  font-size: 16px;
  color: ${Theme.colors.tundora};
  margin-left: 16px;

  @media (min-width: 768px) {
    margin-left: 24px;
  }
`;

export const Content = styled.div`
  padding: 16px;
  border-radius: 24px;
  width: 100%;
  min-height: 30px;
  background-color: white;
  margin-top: 8px;

  @media (min-width: 768px) {
    padding: 24px;
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  border: none;
  border-bottom: #979797 1px solid;
  outline: none;
  font-family: "Quicksand";
  font-size: 16px;
  font-weight: 500;
  resize: none;
  height: 48px;
`;
