import Button from "components/Button";
import styled from "styled-components";
import { Theme } from "styles/theme";

export const Wrapper = styled.div`
  padding: 2.4rem;

  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Flex1 = styled.div`
  flex: 1;
`;

export const Title = styled.div`
  font-family: "Quicksand";
  font-size: 3rem;
  font-weight: 700;
  color: ${Theme.colors.sunsetOrange};

  width: 100%;
  text-align: center;
`;

export const Description = styled.div`
  margin-top: 1.6rem;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: ${Theme.colors.gray};
`;

export const Section = styled.div`
  margin-top: 2.8rem;
  padding: 2.4rem;
  border-radius: 2.5rem;
  background-color: #ffffff;

  display: flex;
  gap: 2rem;
`;

export const ResendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.8rem;
`;

export const ResendTimer = styled.p`
  font-family: "Quicksand";
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: ${Theme.colors.gray};
`;

export const ButtonResend = styled(Button)`
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2.2rem;
  padding: 0;
  height: auto;
`;

export const ButtonWrapper = styled.div`
  margin-top: 2.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
