import { darken } from "polished";
import styled, { css } from "styled-components";
import { Theme } from "styles/theme";

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 32rem;
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .tooltip {
    padding: 0.3rem 0.6rem;
    border-radius: 0.8rem;
    font-family: "Quicksand";
    font-size: 1.2rem;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${Theme.colors.wildSand};

  &:last-child {
    border-bottom: none;
  }
`;

type WrapperButtonProps = {
  cursor: "pointer" | "default";
};

export const WrapperButton = styled.button<WrapperButtonProps>`
  ${({ cursor }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 2.4rem 0;
    margin-right: 1.5rem;
    border: none;
    background-color: transparent;
    cursor: ${cursor};

    &:last-child {
      border-bottom: none;
    }
  `}
`;

export const CardImage = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 0.8rem;
  }

  p {
    font-family: "Quicksand";
    font-size: 1.2rem;
    color: ${Theme.colors.sunsetOrange};
  }
`;

export const CardNumber = styled.div`
  display: flex;
  align-items: center;

  p {
    font-family: "Quicksand";
    font-size: 1.5rem;
    color: ${Theme.colors.gray};
    margin-right: 1.6rem;
  }
`;

export const DeleteButton = styled.button`
  background: ${Theme.colors.sunsetOrange};
  border: none;
  border-radius: 0.4rem;
  padding: 0.4rem;
  cursor: pointer;

  img {
    width: 1.2em;
    height: 1.3rem;
  }

  &:hover {
    background: ${darken(0.15, Theme.colors.sunsetOrange)};
  }
`;

export const ModalWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100vh;
  flex-direction: column;
  padding-bottom: 105px;

  @media (min-width: 768px) {
    height: auto;
    padding-bottom: 0;
  }
`;

export const ConfirmTitle = styled.h1`
  font-size: 2rem;
  font-weight: bold;
`;

export const ConfirmDescription = styled.p`
  margin-top: 16px;
  font-size: 1.7rem;
  font-weight: normal;
`;

export const Container = styled.div`
  padding: 3rem;
`;
