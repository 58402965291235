import styled from "styled-components";
import { Theme } from "styles/theme";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 0.8rem;
  box-shadow: 0 0.8rem 1.4rem 0 rgba(0, 0, 0, 0.06);
`;

export const Image = styled.img`
  height: 72px;
`;

export const Description = styled.div`
  h3 {
    font-family: "Quicksand";
    font-size: 1.6rem;
    font-weight: 600;
    color: ${Theme.colors.tundora};
  }

  p {
    font-family: "Quicksand";
    font-size: 1.2rem;
    font-weight: 500;
    color: ${Theme.colors.gray};
  }
`;
