import { CartType, MerchantServicesAvailable } from "components/Cart/interface";
import useCart from "hooks/useCart";

import AddressItem from "../AdressItem";
import * as S from "./styles";

type DeliveryAddressFormProps = {
  cart: CartType;
  handleAddressLink: () => void;
  handlePickupAddressLink: () => void;
  isBentoPackage: boolean;
};

const DeliveryAddressForm = ({
  cart,
  isBentoPackage,
  handleAddressLink,
  handlePickupAddressLink,
  ...props
}: DeliveryAddressFormProps) => {
  const { changeDelivery } = useCart();

  const servicesAvailable = cart.merchant?.servicesAvailable
    ? new Set(cart.merchant?.servicesAvailable)
    : null;
  const isVoucher = servicesAvailable?.has(MerchantServicesAvailable.voucher);
  const isDonation = servicesAvailable?.has(MerchantServicesAvailable.donation);

  const renderOrderType = () => {
    const hasDelivery =
      servicesAvailable?.has(MerchantServicesAvailable.delivery) || false;
    const hasTakeout =
      servicesAvailable?.has(MerchantServicesAvailable.takeout) || false;

    if (isVoucher) {
      return <S.DeliverySelectButton selected>Voucher</S.DeliverySelectButton>;
    }

    if (isDonation) {
      return <S.DeliverySelectButton selected>Donation</S.DeliverySelectButton>;
    }

    return (
      <>
        {hasTakeout && (
          <S.DeliverySelectButton
            selected={cart.type === "TAKEOUT"}
            onClick={() => changeDelivery("TAKEOUT", cart)}
          >
            Pick-up
          </S.DeliverySelectButton>
        )}
        {hasDelivery && (
          <S.DeliverySelectButton
            selected={cart.type === "DELIVERY"}
            onClick={() => changeDelivery("DELIVERY", cart)}
          >
            Delivery
          </S.DeliverySelectButton>
        )}
      </>
    );
  };

  return (
    <S.Delivery>
      {!isBentoPackage && (
        <S.DeliveryChoose>
          <h4>This order is</h4>
          <div>{renderOrderType()}</div>
        </S.DeliveryChoose>
      )}
      {cart.type === "DELIVERY" && (
        <>
          {isBentoPackage && (
            <S.DeliveryAddress style={{ borderTop: 0, marginTop: 0 }}>
              {cart.pickupAddress && (
                <AddressItem
                  onClick={handlePickupAddressLink}
                  address={cart.pickupAddress}
                  isBentoPackage={isBentoPackage}
                  label="Pick-up address"
                />
              )}

              {!cart.pickupAddress && (
                <>
                  <S.AddAddress onClick={handlePickupAddressLink}>
                    {isBentoPackage && <p>Add pick-up address</p>}
                    <S.RightIcon src="/img/icons-right.svg" />
                  </S.AddAddress>
                </>
              )}
            </S.DeliveryAddress>
          )}
          <S.DeliveryAddress
            style={{ marginTop: isBentoPackage ? 0 : "1.6rem" }}
          >
            {cart.address ? (
              <AddressItem
                onClick={handleAddressLink}
                address={cart.address}
                isBentoPackage={isBentoPackage}
                label="Drop off address"
              />
            ) : (
              <>
                <S.AddAddress onClick={handleAddressLink}>
                  {isBentoPackage && <p>Add drop off address</p>}
                  {!isBentoPackage && <p>Add address</p>}
                  <S.RightIcon src="/img/icons-right.svg" />
                </S.AddAddress>
              </>
            )}
          </S.DeliveryAddress>
        </>
      )}
    </S.Delivery>
  );
};

export default DeliveryAddressForm;
