import { useCallback, useEffect, useState } from "react";

import Button from "components/Button";
import { Modal } from "components/Modal";
import PhoneSelectDialModal, {
  DialCodeType,
} from "components/PhoneSelectDialModal";
import TextField from "components/TextField";
import { useAuth } from "contexts/AuthUserContext";
import useModal from "hooks/useModal";
import api from "services/api";
import { countryDialCodes } from "../PhoneSelectDialModal/countryDialCodes";

import * as S from "./styles";

const selectAndRemoveDialCode = (phone: string) => {
  if (!phone) return "";

  const phoneDialFive = phone.substring(0, 5);
  const dialCodeFive = countryDialCodes.find(
    (dial) => dial.dialCode === phoneDialFive
  );
  if (dialCodeFive) {
    return {
      dialCode: dialCodeFive,
      phoneNumber: phone.replace(dialCodeFive.dialCode, ""),
    };
  }
  const dialCode = countryDialCodes.find((dial) =>
    phone.startsWith(dial.dialCode)
  );
  return { dialCode, phoneNumber: phone.replace(dialCode?.dialCode!, "") };
};

type MagicVoucherModalProps = {
  phone?: string;
  onClose: (openVerify: boolean, phoneNumber?: string) => void;
};

const PhoneValidationModal = ({
  phone = "",
  onClose,
}: MagicVoucherModalProps) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dialCode, setDialCode] = useState<DialCodeType | null>({
    name: "Cayman Islands",
    flag: "🇰🇾",
    code: "KY",
    dialCode: "+1345",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { authUser } = useAuth();
  // const userCoordinates = useUserCoordinates();
  const { open: openDialModal, toggle: toggleDialModal } = useModal();

  const handleValidationSave = useCallback(async () => {
    if (!phoneNumber || !dialCode) return;

    try {
      setIsLoading(true);

      const dataToSave = {
        phoneNumber: `${dialCode?.dialCode}${phoneNumber}`,
      };

      await api.post(`/users/${authUser?.uid}/phone/save`, dataToSave);

      // Toast({
      //   title: "Success",
      //   message: "Phone save successfully",
      //   type: "success",
      // });
      onClose(true, dataToSave.phoneNumber);
    } finally {
      setIsLoading(false);
    }
  }, [authUser, phoneNumber, dialCode]);

  // const reverseGeocodingWithGoogle = useCallback(
  //   async (latitude: number, longitude: number) => {
  //     try {
  //       const { data } = await axios.get(
  //         `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.NEXT_PUBLIC_MAPS_API_KEY}`
  //       );
  //       console.log({ data });
  //     } catch {
  //       console.log("error");
  //     }
  //   },
  //   []
  // );

  // useEffect(() => {
  //   if (userCoordinates) {
  //     reverseGeocodingWithGoogle(
  //       userCoordinates.latitude,
  //       userCoordinates.longitude
  //     );
  //   }
  // }, [userCoordinates]);

  const renderPhoneSelectDialModal = () => {
    return (
      <Modal
        title=""
        open={openDialModal}
        onClose={() => toggleDialModal(false)}
      >
        <PhoneSelectDialModal
          onClose={(dial) => {
            dial && setDialCode(dial);
            toggleDialModal(false);
          }}
        />
      </Modal>
    );
  };

  useEffect(() => {
    if (phone) {
      const data = selectAndRemoveDialCode(phone);
      if (data && data.dialCode && data.phoneNumber) {
        const { dialCode, phoneNumber } = data;
        setPhoneNumber(phoneNumber);
        setDialCode(dialCode);
      }
    }
  }, [phone]);

  return (
    <S.Wrapper>
      <S.Flex1>
        <S.Title>Add Phone</S.Title>

        <S.Description>
          Just in case something happens and we need to contact you
        </S.Description>

        <S.Section>
          <S.SelectDialCode onClick={() => toggleDialModal(true)}>
            <S.FlagEmoji>{dialCode?.flag}</S.FlagEmoji>
            {dialCode?.dialCode ? dialCode?.dialCode : "(+xxx)"}
            <S.Icon src="/img/icon-arrow-down.svg" alt="Icon to open select" />
          </S.SelectDialCode>

          <TextField
            variant="material"
            placeholder="Phone number"
            value={phoneNumber}
            onInput={(v) => setPhoneNumber(v as string)}
            mask="numberClean"
          />
        </S.Section>
      </S.Flex1>

      <S.ButtonWrapper>
        <Button
          color="white"
          onClick={() => onClose(false)}
          disabled={isLoading}
          shadow
        >
          Cancel
        </Button>
        <Button
          onClick={handleValidationSave}
          disabled={isLoading || !phoneNumber || !dialCode}
          loading={isLoading}
        >
          Verify
        </Button>
      </S.ButtonWrapper>

      {renderPhoneSelectDialModal()}
    </S.Wrapper>
  );
};

export default PhoneValidationModal;
