import styled from "styled-components";
import { Theme } from "styles/theme";

export const Wrapper = styled.div`
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

export const ItemName = styled.div`
  font-family: "Quicksand";
  font-size: 16px;
  font-weight: 500;
`;

export const ItemInfo = styled.div``;

export const ItemPrice = styled.div`
  margin: 4px 0 0;
  font-family: "Quicksand";
  font-size: 14px;
  color: ${Theme.colors.gray};
`;
