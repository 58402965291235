import * as S from "./styles";

export type SnackbarProps = {
  icon: React.ReactNode;
  title: string;
  message: string | React.ReactNode;
  open?: boolean;
  playSound?: boolean;
};

const Snackbar = ({
  icon,
  title,
  message,
  open,
  playSound = false,
}: SnackbarProps) => {
  return (
    <S.Wrapper open={open}>
      <S.IconWrapper>{icon}</S.IconWrapper>

      <S.Content>
        <S.Title>{title}</S.Title>
        <S.Message>{message}</S.Message>
      </S.Content>
    </S.Wrapper>
  );
};

export default Snackbar;
