import { ButtonHTMLAttributes } from 'react'

import * as S from './styles'

export type SelectButtonProps = {
  selected: boolean
  children: React.ReactNode
} & ButtonHTMLAttributes<HTMLButtonElement>

const SelectButton = ({ selected, children, ...props }: SelectButtonProps) => (
  <S.Wrapper selected={selected} {...props}>
    {children}
  </S.Wrapper>
)

export default SelectButton
