import { Animation as LoadingAnimation } from "components/Loading/styles";
import { darken, lighten } from "polished";
import styled, { css, DefaultTheme } from "styled-components";
import { Theme } from "styles/theme";
import { ButtonProps } from ".";

export type WrapperProps = {
  hasIcon: boolean;
  isLoading: boolean;
  hasShadow: boolean;
} & Pick<
  ButtonProps,
  "color" | "size" | "fullWidth" | "minimal" | "iconSide" | "iconButton"
>;

const wrapperModifiers = {
  small: (theme: DefaultTheme, iconButton?: boolean) => css`
    height: 3rem;
    line-height: 0.5;
    font-size: 1rem;
    padding: 1.2rem 2.4rem;

    ${iconButton &&
    css`
      padding: 1.3rem;
      height: auto;
      border-radius: 50%;
    `}
  `,
  medium: (theme: DefaultTheme, iconButton?: boolean) => css`
    line-height: 0.8;
    font-size: 1.6rem;
    padding: 1.3rem 3.2rem;
    height: 4.8rem;
    font-weight: bold;

    ${iconButton &&
    css`
      padding: 1.5rem;
      height: auto;
      border-radius: 50%;
    `}
  `,
  large: (theme: DefaultTheme, iconButton?: boolean) => css`
    height: 5rem;
    font-size: 1.8rem;
    padding: 1.2rem 2.4rem;

    ${iconButton &&
    css`
      padding: 1.9rem;
      height: auto;
      border-radius: 50%;
    `}
  `,
  fullWidth: () => css`
    width: 100%;
  `,
  withIcon: (iconSide?: "left" | "right", iconButton?: boolean) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: ${iconSide === "left" ? "row" : "row-reverse"};

    svg {
      width: 1.5rem;
      max-height: 1.5rem;

      & + span {
        ${iconSide === "left" &&
        css`
          margin-left: 0.5rem;
        `}
        ${iconSide === "right" &&
        css`
          margin-right: 0.5rem;
        `}

        ${iconButton &&
        css`
          margin: 0;
        `}
      }
    }
  `,
  minimal: (theme: DefaultTheme, loading?: boolean) => css`
    background: none;
    color: ${Theme.colors.sunsetOrange};

    &:hover {
      color: ${darken(0.2, Theme.colors.sunsetOrange)};
    }

    ${loading &&
    css`
      ${LoadingAnimation} {
        border-color: rgba(255, 76, 76, 0.2);
        border-left-color: ${Theme.colors.sunsetOrange};
      }
    `}
  `,
  loading: (
    theme: DefaultTheme,
    iconSide?: "left" | "right",
    iconButton?: boolean
  ) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: ${iconSide === "left" ? "row" : "row-reverse"};

    ${LoadingAnimation} {
      width: 1.5rem;
      height: 1.5rem;
      border-color: #fff;
      border-left-color: ${lighten(0.1, Theme.colors.sunsetOrange)};

      & + span {
        ${iconSide === "left" &&
        css`
          margin-left: 0.5rem;
        `}
        ${iconSide === "right" &&
        css`
          margin-right: 0.5rem;
        `}

        ${iconButton &&
        css`
          margin: 0;
        `}
      }
    }
  `,
  disabled: (theme: DefaultTheme, minimal?: boolean) => css`
    background: ${Theme.colors.gray};
    cursor: not-allowed;

    &:hover {
      background: ${Theme.colors.gray};
    }

    ${minimal &&
    css`
      background: none;
      color: ${lighten(1, Theme.colors.sunsetOrange)};

      &:hover {
        background: none;
        color: ${lighten(0.1, Theme.colors.sunsetOrange)};
      }
    `}
  `,
  primary: (theme: DefaultTheme, minimal?: boolean, disabled?: boolean) => css`
    background: ${!minimal ? Theme.colors.sunsetOrange : "none"};
    color: ${!minimal ? "#FFF" : Theme.colors.sunsetOrange};

    &:hover {
      background: ${minimal ? "none" : darken(0.2, Theme.colors.sunsetOrange)};
    }

    ${disabled &&
    css`
      color: ${lighten(0.1, !minimal ? "#FFF" : Theme.colors.sunsetOrange)};
      background: ${!minimal ? lighten(0.1, Theme.colors.gray) : "none"};

      &:hover {
        color: ${lighten(0.1, !minimal ? "#FFF" : Theme.colors.sunsetOrange)};
        background: ${!minimal ? lighten(0.1, Theme.colors.gray) : "none"};
      }
    `}
  `,
  white: (theme: DefaultTheme, minimal?: boolean, disabled?: boolean) => css`
    background: ${!minimal ? "#FFF" : "none"};
    color: ${Theme.colors.sunsetOrange};

    &:hover {
      background: ${minimal ? "none" : darken(0.02, "#FFF")};
    }

    ${disabled &&
    css`
      color: ${lighten(0.1, Theme.colors.sunsetOrange)};

      &:hover {
        color: ${lighten(0.1, Theme.colors.sunsetOrange)};
      }
    `}
  `,
  shadow: () => css`
    box-shadow: 0 0.3rem 1rem 0 rgba(191, 206, 236, 0.6);
  `,
};

export const Wrapper = styled.button<WrapperProps>`
  ${({
    theme,
    size,
    color,
    fullWidth,
    hasIcon,
    iconSide,
    iconButton,
    minimal,
    isLoading,
    hasShadow,
    disabled,
  }) => css`
    border: 0;
    border-radius: 3rem;
    cursor: pointer;
    font-family: "Nunito";
    font-weight: 500;
    transition: background 0.2s ease;

    ${!!size && wrapperModifiers[size](theme, iconButton)};
    ${fullWidth && wrapperModifiers.fullWidth()};
    ${hasIcon && wrapperModifiers.withIcon(iconSide, iconButton)};
    ${isLoading && wrapperModifiers.loading(theme, iconSide, iconButton)};
    ${minimal && wrapperModifiers.minimal(theme, isLoading)};
    ${disabled && wrapperModifiers.disabled(theme, !!minimal)};
    ${!!color && wrapperModifiers[color](theme, !!minimal, disabled)};
    ${hasShadow && wrapperModifiers.shadow()};
  `}
`;
