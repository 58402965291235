import { ButtonComplete, PaymentTitle } from "components/Cart/styles";
import styled from "styled-components";

export const ListTitle = styled(PaymentTitle)`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`;

export const ButtonNew = styled(ButtonComplete)``;
