import Button from "components/Button";
import { darken } from "polished";
import styled, { css } from "styled-components";
import { Theme } from "styles/theme";
import { AlertProps } from ".";

type WrapperProps = Pick<AlertProps, "type"> & {
  open: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ open, type }) => css`
    display: ${open ? "flex" : "none"};
    gap: 1.6rem;
    padding: 1.6rem;
    padding-right: 3.2rem;
    border-radius: 0.8rem;
    font-size: 1.6rem;
    color: ${Theme.colors.tundora};
    position: relative;

    ${type === "success" &&
    css`
      background-color: #e4ffea;
    `}
    ${type === "danger" &&
    css`
      background-color: #ffe8e8;
    `}
    ${type === "warning" &&
    css`
      background-color: #fff6ee;
    `}

    + div {
      margin-top: 1.2rem;
    }

    ${ButtonClose} {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  `}
`;

export const ButtonClose = styled(Button)`
  border-radius: 1.5rem;
  width: 3rem;
  height: 3rem;
  padding: 0;
  font-size: 2.4rem;
  color: ${Theme.colors.tundora};

  &:hover {
    color: ${darken(0.2, Theme.colors.tundora)};
  }
`;
