import { InputHTMLAttributes, useState, useEffect } from "react";
import { InputNumberProps } from "./interface";
import * as S from "./styles";
import { HiPlus, HiMinus } from "react-icons/hi";
import { Theme } from "styles/theme";

const InputNumber = ({
  border = false,
  value,
  onChangeValue,
  minValue,
  maxValue,
  disableIncrease,
  ...props
}: InputNumberProps) => {
  const [currentValue, setCurrentValue] = useState<number>(value);

  useEffect(() => {
    onChangeValue(currentValue);
  }, [currentValue]);

  const increase = () => {
    if (disableIncrease) return;
    if (currentValue === maxValue) return;

    setCurrentValue(currentValue + 1);
  };

  const decrease = () => {
    const newValue = currentValue - 1;

    if (minValue && newValue < minValue) {
      setCurrentValue(minValue);
      return;
    }

    setCurrentValue(currentValue - 1);
  };

  const getPlusIconColor = () => {
    if (disableIncrease) return Theme.colors.gray;
    if (currentValue === maxValue) return Theme.colors.gray;
    return Theme.colors.sunsetOrange;
  };

  return (
    <S.Wrapper border={border}>
      {currentValue > 0 && (
        <HiMinus
          onClick={decrease}
          color={Theme.colors.sunsetOrange}
          size="20px"
        ></HiMinus>
      )}
      {currentValue > 0 && <S.Value>{currentValue}</S.Value>}
      <HiPlus
        onClick={increase}
        color={getPlusIconColor()}
        size="30px"
      ></HiPlus>
    </S.Wrapper>
  );
};

export default InputNumber;
