import { MerchantType } from "components/Cart/interface";
import { analytics } from "config/firebase.config";
import { logEvent, setUserId } from "firebase/analytics";
import { Coordinates } from "./useUserCoordinates";

type Item = {
  itemId: string;
  itemName: string;
  itemCategory: string[];
  itemValue?: number;
  merchantId: string | MerchantType;
  quantity: number;
};

export const useAnalytics = () => {
  const defineUserId = (id: string) => {
    setUserId(analytics!, id);
  };

  const logSignUpEvent = (method: string) => {
    logEvent(analytics!, "sign_up", { method });
  };

  const handleItemCategory = (itemCategory: string | string[]) => {
    const _itemCategory = Array.isArray(itemCategory)
      ? itemCategory?.join(", ")
      : itemCategory;

    return _itemCategory;
  };

  const handleMerchantId = (merchantId: string | MerchantType) => {
    const _merchantId =
      typeof merchantId === "object" ? merchantId.id : merchantId;

    return _merchantId;
  };

  const logAddToCart = ({
    itemCategory,
    itemId,
    itemName,
    merchantId,
    quantity,
  }: Item) => {
    const _itemCategory = handleItemCategory(itemCategory);
    const _merchantId = handleMerchantId(merchantId);

    logEvent(analytics!, "add_to_cart", {
      itemId: itemId,
      itemName: itemName,
      itemCategory: _itemCategory,
      itemLocationId: _merchantId,
      quantity,
    });
  };

  const logPurchase = ({
    transactionId,
    value,
    origin,
  }: {
    transactionId: string;
    value: number;
    origin: string;
  }) => {
    logEvent(analytics!, "purchase", {
      transaction_id: transactionId,
      value,
      currency: "KYD",
      origin,
    });
  };

  const logViewItem = ({
    itemCategory,
    itemId,
    itemName,
    merchantId,
  }: Omit<Item, "quantity">) => {
    const _itemCategory = handleItemCategory(itemCategory);
    const _merchantId = handleMerchantId(merchantId);

    logEvent(analytics!, "view_item", {
      itemId: itemId,
      itemName: itemName,
      merchant: _merchantId,
      itemCategory: _itemCategory,
    });
  };

  const logMerchantView = ({
    merchantId,
    merchantName,
    userCoordinates,
  }: {
    merchantName: string;
    merchantId: string;
    userCoordinates: Coordinates | null | undefined;
  }) => {
    logEvent(analytics!, "merchant_view", {
      name: merchantName,
      id: merchantId,
      merchantId: merchantId,
      lat: userCoordinates?.latitude || null,
      lng: userCoordinates?.longitude || null,
    });
  };

  const logCategoryView = ({
    categoryName,
    userCoordinates,
  }: {
    categoryName: string;
    userCoordinates: Coordinates | null;
  }) => {
    logEvent(analytics!, "category_view", {
      category: categoryName,
      lat: userCoordinates?.latitude || null,
      lng: userCoordinates?.longitude || null,
    });
  };

  const logGlobalSearch = (term: string) => {
    logEvent(analytics!, "search", {
      term,
    });
  };

  const logMenuSearch = (term: string, merchantId?: string) => {
    logEvent(analytics!, "menu_search", {
      term,
      merchantId: merchantId || "",
    });
  };

  const logBeginCheckout = (value: number) => {
    logEvent(analytics!, "begin_checkout", {
      currency: "KYD",
      value,
    });
  };

  const logDownloadAppBanner = () => {
    logEvent(analytics!, "download_app_banner");
  };

  const logDealList = (campaignId: string, merchantId: string, userId: string) => {
    logEvent(analytics!, "campaign_magic_deal", {
      campaignId,
      merchantId,
      userId,
    });
  };

  const logGetDeal = (campaignId: string, merchantId: string, userId: string) => {
    logEvent(analytics!, "get_magic_deal", {
      campaignId,
      merchantId,
      userId,
    });
  };

  const logVisitMenu = (campaignId: string, merchantId: string, userId: string) => {
    logEvent(analytics!, "campaign_merchant_view", {
      campaignId,
      merchantId,
      userId,
    });
  };

  return {
    logSignUpEvent,
    logAddToCart,
    logPurchase,
    logViewItem,
    logMerchantView,
    logCategoryView,
    logGlobalSearch,
    logBeginCheckout,
    logMenuSearch,
    defineUserId,
    logDownloadAppBanner,
    logDealList,
    logGetDeal,
    logVisitMenu,
  };
};
