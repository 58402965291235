import styled, { css } from "styled-components";
import { Theme } from "styles/theme";

import { TextFieldProps } from ".";

export type IconPositionProps = Pick<TextFieldProps, "iconPosition">;

export type WrapperProps = Pick<
  TextFieldProps,
  "disabled" | "variant" | "iconPosition"
> & {
  error?: boolean;
  hasIcon: boolean;
};

export const InputWrapper = styled.div`
  display: flex;
  background: #fff;
  border-radius: 1.3rem;
  padding: 0 1.2rem;
  border: 0.2rem solid transparent;
  transition: border 0.3s ease-in-out;
  position: relative;

  &:focus-within {
    border: 2px solid #c5c0c0;
  }
`;

export const Input = styled.input<IconPositionProps>`
  ${({ iconPosition }) => css`
    color: ${Theme.colors.tundora};
    font-family: 'Quicksand';
    font-size: 1.2rem;
    padding: 1.2rem 0;
    padding-${iconPosition}: 1.2rem;
    background: transparent;
    border: 0;
    outline: none;
    width: 100%;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 2.4rem #FFF inset;
      filter: none
    }

    &::placeholder {
      color: ${Theme.colors.gray};
    }
  `}
`;

export const Label = styled.label`
  font-size: 1.6rem;
  color: ${Theme.colors.tundora};
  cursor: pointer;

  span {
    color: ${Theme.colors.sunsetOrange};
    font-size: 1.6rem;
    line-height: 0.1rem;
    display: inline-block;
    top: 8px;
    align-items: center;
    justify-content: center;
    width: 1.4rem;
  }
`;

export const Icon = styled.div<IconPositionProps>`
  ${({ iconPosition }) => css`
    display: flex;
    width: 2.2rem;
    max-height: 3.9rem;
    color: #c5c0c0;
    order: ${iconPosition === "right" ? 1 : 0};

    & > svg,
    & > img {
      width: 100%;
    }
  `}
`;

const wrapperModifiers = {
  error: () => css`
    ${Icon},
    ${Label} {
      color: #f34040 !important;
    }
  `,
  disabled: () => css`
    ${Label},
    ${Input},
    ${Icon} {
      cursor: not-allowed;
      color: ${Theme.colors.gray};

      &::placeholder {
        color: currentColor;
      }
    }
  `,
  material: (
    hasIcon: boolean,
    iconPosition?: "left" | "right",
    disabled?: boolean
  ) => css`
    ${Label} {
      font-weight: 500;
      font-size: 1rem;
      color: ${Theme.colors.gray};
    }

    ${Icon} {
      width: 2rem;
      max-height: 2rem;
    }

    ${InputWrapper} {
      border-radius: 0;
      border-bottom: 1px solid #979797;
      padding: 0;
      background-color: transparent;

      ${disabled &&
      css`
        border-bottom: 1px solid #dcdcdc;
      `}

      &:focus-within {
        border: 2px solid transparent;
        border-bottom: 1px solid #c5c0c0;
      }
    }

    ${Input} {
      font-family: "Quicksand";
      color: ${Theme.colors.tundora};
      font-size: 1.6rem;
      font-weight: 500;
      padding: 0 0 0.6rem;
      ${hasIcon && iconPosition && css`padding-${iconPosition}: 0.8rem;`}
    }
  `,
};

export const Error = styled.p`
  color: #f34040;
  font-size: 1.2rem;
`;

export const Wrapper = styled.div<WrapperProps>`
  ${({ error, disabled, iconPosition, hasIcon, variant }) => css`
    width: 100%;

    ${error && wrapperModifiers.error()}
    ${variant === "material" &&
    wrapperModifiers.material(hasIcon, iconPosition, disabled)}
    ${disabled && wrapperModifiers.disabled()}
  `}
`;
