import { useState } from "react";

import * as S from "./styles";

export type AlertProps = {
  children: React.ReactNode;
  type?: "success" | "danger" | "warning";
  onClose?: () => void;
  closeButton?: boolean;
  style?: React.CSSProperties;
  className?: string;
};

const Alert = ({
  children,
  type = "success",
  onClose,
  closeButton = false,
  ...rest
}: AlertProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  return (
    <S.Wrapper type={type} open={isOpen} {...rest}>
      {closeButton && (
        <S.ButtonClose onClick={handleClose} minimal>
          &times;
        </S.ButtonClose>
      )}
      {children}
    </S.Wrapper>
  );
};

export default Alert;
