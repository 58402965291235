import { darken, lighten } from "polished";
import styled, { css } from "styled-components";
import { Theme } from "styles/theme";

export const Wrapper = styled.div<{ open?: boolean }>`
  ${({ open = false }) => css`
    position: fixed;
    top: 1rem;
    left: 50%;
    min-width: 35rem;
    padding: 16px 32px;
    border-radius: 8px;
    margin-bottom: 8px;
    background-color: #2e2a2a;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    gap: 2.4rem;
    border-radius: 150px;
    border: 1px solid ${darken(0.35, Theme.colors.silver)};
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
    z-index: 102;
    transition: transform 0.2s ease-in-out;

    transform: translateY(${open ? "0" : "-130%"}) translateX(-50%);
  `}
`;

export const IconWrapper = styled.div`
  color: ${lighten(1, Theme.colors.tundora)};
`;

export const Content = styled.div`
  text-align: center;
`;

export const Title = styled.p`
  color: ${lighten(1, Theme.colors.tundora)};
  line-height: 1.6;
`;

export const Message = styled.p`
  color: ${lighten(0.5, Theme.colors.tundora)};
`;
