import styled, { css } from "styled-components";
import { Theme } from "styles/theme";
import { RadioProps } from "./interface";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

type InputProps = Pick<RadioProps, "disabled">;

export const Input = styled.input<InputProps>`
  ${({ disabled }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    width: 1.5rem;
    height: 1.5rem;
    border: 0.1rem solid ${Theme.colors.gray};
    border-radius: 50%;
    background: transparent;
    transition: background 200;
    outline: none;
    cursor: pointer;
    &:focus {
      box-shadow: 0 0 0.3rem ${Theme.colors.sunsetOrange};
    }
    &:before {
      content: "";
      width: 1.1rem;
      height: 1.1rem;
      border-radius: 50%;
      background: ${Theme.colors.sunsetOrange};
      transition: opacity 200;
      opacity: 0;
      position: absolute;
    }
    &:checked {
      position: relative;
      border: 0.1rem solid ${Theme.colors.sunsetOrange};

      &:before {
        opacity: 1;
      }
    }
    ${disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
  `}
`;

type LabelProps = Pick<RadioProps, "disabled">;

export const Label = styled.label<LabelProps>`
  ${({ disabled }) => css`
    padding-left: 1rem;
    color: ${Theme.colors.gray};
    font-weight: 600;
    line-height: 1;
    cursor: pointer;
    ${disabled &&
    css`
      cursor: not-allowed;
    `}
  `}
`;
