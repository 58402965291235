import { createContext, useContext } from "react";

import { AuthUser, useFirebaseAuth } from "hooks/useFirebaseAuth";

interface AuthUserContextProps {
  authUser: AuthUser | null;
  isLoadingAuthUser: boolean;
  loadingGoogle: boolean;
  loadingFacebook: boolean;
  loadingApple: boolean;
  isSigning: boolean;
  isCreatingAccount: boolean;
  loginWithGoogle: () => void;
  loginWithFacebook: () => void;
  loginWithApple: () => void;
  createUserWithEmailAndPass: (
    name: string,
    email: string,
    password: string
  ) => void;
  signInWithEmailAndPass: (email: string, password: string) => void;
  confirmPassReset: (code: string, password: string) => void;
  logout: () => void;
}

const AuthUserContext = createContext({
  authUser: null,
  isLoadingAuthUser: true,
  loadingGoogle: false,
  loadingFacebook: false,
  loadingApple: false,
  isSigning: false,
  isCreatingAccount: false,
  loginWithGoogle: async () => {},
  loginWithFacebook: async () => {},
  loginWithApple: async () => {},
  logout: async () => {},
  createUserWithEmailAndPass: async (
    name: string,
    email: string,
    password: string
  ) => {},
  confirmPassReset: async (code: string, password: string) => {},
  signInWithEmailAndPass: async (email: string, password: string) => {},
} as AuthUserContextProps);

type AuthUserProviderProps = {
  children: React.ReactNode;
};

export function AuthUserProvider({ children }: AuthUserProviderProps) {
  const auth = useFirebaseAuth();
  return (
    <AuthUserContext.Provider value={auth}>{children}</AuthUserContext.Provider>
  );
}

export const useAuth = () => useContext(AuthUserContext);
