export const svgBlinking = (w: number | string, h: number | string) => `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="${w}"
      height="${h}"
      viewBox="0 0 ${w} ${h}"
    >
      <g id="Page-1" fill="none" fill-rule="evenodd">
        <rect id="rectangle" width="${w}" height="${h}" fill="#dddddd" />
        <animate
          attributeName="opacity"
          dur="1s"
          values="0.1;0.5;0.1"
          repeatCount="indefinite"
          begin="0.1"
        ></animate>
      </g>
    </svg>`;

export const toBase64 = (str: string) =>
  typeof window === "undefined"
    ? Buffer.from(str).toString("base64")
    : window.btoa(str);
