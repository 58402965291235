import React, { useState } from "react";

import { DescriptionProps } from "./interface";
import * as S from "./styles";

const Description = ({
  description,
  onChangeDescription,
}: DescriptionProps) => {
  return (
    <S.Wrapper>
      <S.Title>Any comments?</S.Title>
      <S.Content>
        <S.Textarea
          onChange={onChangeDescription}
          value={description}
          placeholder="Add instructions"
        ></S.Textarea>
      </S.Content>
    </S.Wrapper>
  );
};

export default Description;
