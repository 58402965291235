import styled from "styled-components";
import { Theme } from "styles/theme";

export const Wrapper = styled.div`
  padding: 2.4rem;

  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Flex1 = styled.div`
  flex: 1;
`;

export const Title = styled.div`
  font-family: "Quicksand";
  font-size: 3rem;
  font-weight: 700;
  color: ${Theme.colors.sunsetOrange};
`;

export const Description = styled.div`
  font-family: "Quicksand";
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.22;
  color: ${Theme.colors.gray};
  margin-top: 1.6rem;
`;

export const Section = styled.div`
  margin-top: 2.8rem;
  padding: 2.4rem;
  border-radius: 2.5rem;
  background-color: #ffffff;
`;

export const ButtonWrapper = styled.div`
  margin-top: 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MagicVoucherContainer = styled.div`
  position: relative;

  img {
    position: absolute;
    top: calc(50% - 75px);
    left: calc(50% - 75px);
    width: 150px;
  }

  span {
    animation: blinker 1s linear infinite;
    font-family: "Quicksand";
    font-size: 1.8rem;
    font-weight: 600;
    position: absolute;
    top: calc(80%);
    left: calc(50% - 75px);
    z-index: 4000;
    color: white;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`;

export const MagicVoucherPanel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(30, 30, 30, 0.8);
  width: 100vw;
  height: 100vh;
`;
