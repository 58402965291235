import {
  ButtonComplete,
  CloseButton,
  PaymentTitle,
} from "components/Cart/styles";
import { Wrapper as WrapperRadio, Label } from "components/Radio/styles";
import styled, { css } from "styled-components";
import { Theme } from "styles/theme";

export const NewAddressTitle = styled(PaymentTitle)`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`;

export const FormAddress = styled.div`
  margin-top: 2.5rem;
`;

export const FormRow = styled.div`
  margin-bottom: 3.2rem;
  position: relative;

  input {
    &::placeholder {
      color: ${Theme.colors.alto};
    }
  }
`;

export const Input = styled.input`
  width: 100%;
  color: ${Theme.colors.tundora};
  font-family: "Quicksand";
  font-size: 1.2rem;
  padding: 1.2rem;
  border-radius: 1.3rem;
  border: 0.1rem solid ${Theme.colors.silver};

  &::placeholder {
    color: ${Theme.colors.gray};
  }

  &:focus {
    outline: none;
    border: 0.1rem solid ${Theme.colors.alto};
  }
`;

export const Icon = styled.span`
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
`;

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
`;

export const TextHelp = styled.div`
  h4 {
    font-family: "Quicksand";
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    color: ${Theme.colors.gray};
  }

  p {
    font-family: "Quicksand";
    font-size: 1.2rem;
    line-height: 2rem;
    color: ${Theme.colors.gray};
  }
`;

export const ButtonSave = styled(ButtonComplete)``;

export const AlertWrapper = styled.div`
  position: absolute;
  top: 2.4rem;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 2.4rem;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 2.4rem;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    bottom: 9.5rem !important;
  }
`;

export const ButtonConfirmMap = styled(ButtonComplete)``;

export const Marker = styled.img`
  position: absolute;
  top: 40%;
  left: 46.6%;
  z-index: 9;
`;

export const BackStepButton = styled(CloseButton)`
  left: -5.5rem;
`;

export const Address = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2.4rem;
`;

export const AddressImage = styled.div`
  display: flex;
  align-items: center;

  p {
    font-family: "Quicksand";
    font-size: 1.2rem;
    color: ${Theme.colors.sunsetOrange};
  }
`;

export const AddressDescription = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-left: 2.4rem;

  h3 {
    font-family: "Quicksand";
    font-size: 1.5rem;
    color: ${Theme.colors.tundora};
    font-weight: 600;
    line-height: 1.07;
  }

  p {
    font-family: "Quicksand";
    font-size: 1.2rem;
    color: ${Theme.colors.gray};
    line-height: 1.42;
  }
`;

export const RadioInline = styled.div`
  display: flex;

  ${Label} {
    font-size: 1.5rem;
  }

  ${WrapperRadio} {
    margin-right: 3.2rem;
  }
`;

export const Quarantine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const QuarantineDescription = styled.div`
  h3 {
    font-family: "Quicksand";
    font-size: 1.5rem;
    color: ${Theme.colors.tundora};
    font-weight: 600;
    line-height: 1.07;
  }

  p {
    font-family: "Quicksand";
    font-size: 1.2rem;
    color: ${Theme.colors.gray};
    line-height: 1.42;
  }
`;
