import { Dispatch, SetStateAction } from "react";

import FormCard from "components/FormCard";

import * as C from "../../styles";
import * as S from "./styles";

type FormCardProps = {
  hasToFetchSettings: boolean;
  handleCloseNewCard: Dispatch<SetStateAction<boolean>>;
  handleOpenCards: Dispatch<SetStateAction<boolean>>;
  isOpened: boolean;
};

const FormCardCart = ({
  hasToFetchSettings,
  handleCloseNewCard,
  handleOpenCards,
  isOpened,
}: FormCardProps) => {
  return (
    <C.CartContent>
      <C.MobileMerchantHeader>
        <h2>Add card</h2>

        <C.MobileCloseButton onClick={() => handleCloseNewCard(false)}>
          <img src="/img/icons-close.svg" />
        </C.MobileCloseButton>
      </C.MobileMerchantHeader>

      <C.OverflowContent style={{ height: "100%" }}>
        <C.Section>
          <S.AddCardTitle>Add card</S.AddCardTitle>

          <FormCard
            hasToFetchSettings={hasToFetchSettings}
            handleCloseNewCard={handleCloseNewCard}
            handleOpenCards={handleOpenCards}
            isOpened={isOpened}
          />
        </C.Section>
      </C.OverflowContent>
    </C.CartContent>
  );
};

export default FormCardCart;
