import styled from "styled-components";
import { Theme } from "styles/theme";

export const Button = styled.button`
  background-color: ${Theme.colors.sunsetOrange};
  color: white;
  font-weight: 600;
  padding: 16px 32px;
  border-radius: 32px;
  border: none;
  outline: none;
  cursor: pointer;

  &:disabled {
    background-color: ${Theme.colors.gray};
    color: white;
  }
`;

export const NegativeButton = styled.button`
  background-color: white;
  color: ${Theme.colors.sunsetOrange};
  border: 2px solid ${Theme.colors.sunsetOrange};
  font-weight: 600;
  padding: 16px 32px;
  border-radius: 32px;
  outline: none;
  cursor: pointer;

  &:disabled {
    background-color: ${Theme.colors.gray};
    color: white;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 3rem;
  width: 100%;
  display: flex;
  justify-content: end;

  button {
    margin-left: 16px;
  }
`;
