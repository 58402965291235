import { ReactNode } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { IoCloseCircle, IoCloseOutline } from "react-icons/io5";
import { TiWarning } from "react-icons/ti";
import { toast, TypeOptions } from "react-toastify";

import * as S from "./styles";
import Button from "components/Button";

export type ToastProps = {
  type?: TypeOptions;
  title: string;
  message: string | ReactNode;
  actionButton?: boolean;
  onClickActionButton?: () => void;
  actionButtonText?: string;
};

const getBgColor = (type: TypeOptions) => {
  if (type === "error") return "#F9C3C3";
  if (type === "success") return "#DCF0E7";
  if (type === "warning") return "#FBE2C9";
  if (type === "info") return "#E7F1F9";
};

const getIcon = (type: TypeOptions) => {
  if (type === "error") return <IoCloseCircle size={24} color="#FF4C4C" />;
  if (type === "success")
    return <BsFillCheckCircleFill size={20} color="#43CB8F" />;
  if (type === "warning") return <TiWarning size={22} color="#FBA240" />;
  if (type === "info") return <AiFillInfoCircle size={22} color="#4C9AFF" />;
};

const Toast = ({
  type = "error",
  title,
  message,
  actionButton = false,
  actionButtonText = "",
  onClickActionButton,
}: ToastProps) =>
  toast(
    <S.ToastContainer>
      <S.Title>
        {getIcon(type)}
        {title}
      </S.Title>
      <S.Message>{message}</S.Message>
      {actionButton && (
        <Button size="small" onClick={onClickActionButton}>
          {actionButtonText}
        </Button>
      )}
    </S.ToastContainer>,
    {
      type,
      theme: "colored",
      hideProgressBar: true,
      style: {
        borderRadius: "11px",
        backgroundColor: getBgColor(type),
      },
      icon: false,
      closeButton: () => (
        <S.CloseButton>
          <IoCloseOutline size={20} color="#433D3D" />
        </S.CloseButton>
      ),
    }
  );

Toast.dismiss = toast.dismiss;

export default Toast;
