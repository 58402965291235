import { ButtonComplete, PaymentTitle } from "components/Cart/styles";
import styled from "styled-components";

export const ListTitle = styled(PaymentTitle)`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 32rem;
`;

export const ButtonNew = styled(ButtonComplete)``;

export const Container = styled.div`
  padding: 3rem;
`;
