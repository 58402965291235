import {
  FacebookAuthProvider,
  getAuth,
  getIdToken,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  confirmPasswordReset,
} from "firebase/auth";
import { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { useRouter } from "next/router";

import api from "services/api";
import { useAnalytics } from "./useAnalytics";
import Toast from "components/Toast";

export interface AuthUser {
  uid: string;
  email: string;
  photoUrl: string;
  name: string;
  token: string;
}

const formatAuthUser = async (user: User) => {
  const photoUrl =
    user.photoURL ||
    user?.providerData?.find((info) => info.photoURL)?.photoURL ||
    "";

  return {
    uid: user.uid,
    uuid: user.uid,
    photoUrl,
    email: user.email || "",
    name: user.displayName || "",
    token: (await user.getIdToken()) || "",
  };
};

const errorMapping: { [key: string]: string } = {
  "auth/invalid-email": "You have entered an invalid e-mail or password",
  "auth/user-not-found": "User not found!",
  "auth/wrong-password": "You have entered an invalid e-mail or password",
  "auth/weak-password":
    "Weak password, password must to be 6 characters or more",
  "auth/email-already-in-use": "E-mail already in use",
  "auth/too-many-requests":
    "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later",
  "auth/operation-not-allowed": "Server error, please try again later.",
  "auth/user-disabled": "Account disabled by Administrator",
};

export function useFirebaseAuth() {
  const [authUser, setAuthUser] = useState<AuthUser | null>(null);
  const [isLoadingAuthUser, setIsLoadingAuthUser] = useState(true);
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [loadingFacebook, setLoadingFacebook] = useState(false);
  const [loadingApple, setLoadingApple] = useState(false);
  const [isSigning, setIsSigning] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const { logSignUpEvent, defineUserId } = useAnalytics();
  const { push } = useRouter();

  const authStateChanged = async (user: User | null) => {
    Sentry.addBreadcrumb({
      category: "auth",
      message: "Auth State Changed" + user?.uid,
      level: "info",
    });

    if (!user) {
      setAuthUser(null);
      setLoadingGoogle(false);
      setLoadingFacebook(false);
      setLoadingApple(false);
      setIsLoadingAuthUser(false);
      return;
    }

    const formattedUser = await formatAuthUser(user);
    defineUserId(formattedUser.uid);
    localStorage.setItem("authUser", JSON.stringify(formattedUser));
    api.defaults.headers.Authorization = `Bearer ${formattedUser.token}`;
    setAuthUser(formattedUser);
    setLoadingGoogle(false);
    setLoadingFacebook(false);
    setLoadingApple(false);
    setIsLoadingAuthUser(false);
  };

  const createUserWithEmailAndPass = async (
    name: string,
    email: string,
    password: string
  ) => {
    const auth = getAuth();
    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (result) => {
        // Signed in
        setIsCreatingAccount(true);
        const user = result.user;

        if (auth?.currentUser)
          await updateProfile(auth?.currentUser, {
            displayName: name,
          }).catch((err) => console.log(err));

        await authStateChanged(user);
        push("/");
        // ...
      })
      .catch((error) => {
        Toast({
          type: "error",
          message: errorMapping[error.code],
          title: "Something wrong",
        });
        // ..
      })
      .finally(() => setIsCreatingAccount(false));
  };

  const signInWithEmailAndPass = async (email: string, password: string) => {
    const auth = getAuth();
    setIsSigning(true);
    await signInWithEmailAndPassword(auth, email, password)
      .then(async (result) => {
        // Signed in
        const user = result.user;

        await authStateChanged(user);
        push("/");
        // ...
      })
      .catch((error) => {
        Toast({
          type: "error",
          message: errorMapping[error.code],
          title: "Something wrong",
        });
        // ..
      })
      .finally(() => setIsSigning(false));
  };

  const confirmPassReset = async (code: string, password: string) => {
    const auth = getAuth();
    await confirmPasswordReset(auth, code, password)
      .then(async (result) => {
        setIsSigning(true);
        // Signed in
        Toast({
          type: "success",
          message: "Sign in with your new password",
          title: "Password updated",
        });

        push("/login-with-email");
        // ...
      })
      .catch((error) => {
        Toast({
          type: "error",
          message: errorMapping[error.code],
          title: "Something wrong",
        });
        // ..
      })
      .finally(() => setIsSigning(false));
  };

  const loginWithGoogle = async () => {
    setLoadingGoogle(true);
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential?.accessToken;
        const user = result.user;
        authStateChanged(user);
        logSignUpEvent("Google");
      })
      .catch(() => {
        setLoadingGoogle(false);
        // const credential = GoogleAuthProvider.credentialFromError(error);
      });
  };

  const loginWithFacebook = async () => {
    setLoadingFacebook(true);
    const provider = new FacebookAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        // const credential = FacebookAuthProvider.credentialFromResult(result);
        // const accessToken = credential?.accessToken;
        const user = result.user;
        authStateChanged(user);
        logSignUpEvent("Facebook");
      })
      .catch(() => {
        setLoadingFacebook(false);
        // const credential = FacebookAuthProvider.credentialFromError(error);
      });
  };

  const loginWithApple = async () => {
    setLoadingApple(true);
    const provider = new OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        // const credential = FacebookAuthProvider.credentialFromResult(result);
        // const accessToken = credential?.accessToken;
        const user = result.user;
        authStateChanged(user);
        logSignUpEvent("Apple");
      })
      .catch(() => {
        setLoadingApple(false);
        // const credential = FacebookAuthProvider.credentialFromError(error);
      });
  };

  const logout = async () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        setAuthUser(null);
        localStorage.removeItem("authUser");
        push("/");
      })
      .catch(() => {
        setAuthUser(null);
        localStorage.removeItem("authUser");
      });
  };

  //  force refresh the token every 10 minutes
  useEffect(() => {
    const handle = setInterval(async () => {
      refreshToken();
    }, 10 * 60 * 1000);
    return () => clearInterval(handle);
  }, []);

  const refreshToken = () => {
    const auth = getAuth();
    if (auth.currentUser) {
      getIdToken(auth.currentUser, true).then(() => {
        onAuthStateChanged(auth, authStateChanged);
      });
    }
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, authStateChanged);
  }, []);

  return {
    refreshToken,
    authUser,
    isLoadingAuthUser,
    loadingGoogle,
    loadingFacebook,
    loadingApple,
    loginWithGoogle,
    loginWithFacebook,
    loginWithApple,
    logout,
    createUserWithEmailAndPass,
    signInWithEmailAndPass,
    isSigning,
    isCreatingAccount,
    confirmPassReset,
  };
}
