import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
import * as Sentry from "@sentry/browser";
import axios, { AxiosError, AxiosResponse } from "axios";
import { User, getAuth, getIdToken, onAuthStateChanged } from "firebase/auth";
import Router from "next/router";

import Toast from "components/Toast";

getCurrentBrowserFingerPrint().then((fingerprint) => {
  api.defaults.headers["bento-fingerprint"] = fingerprint;
});

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

export type ErrorResponseType = {
  statusCode: number;
  message: string;
  error: string;
  inRange?: boolean;
};

const formatAuthUser = async (user: User) => ({
  uid: user.uid,
  uuid: user.uid,
  email: user.email || "",
  name: user.displayName || "",
  token: (await user.getIdToken()) || "",
});

const refreshToken = () => {
  const auth = getAuth();
  if (auth.currentUser) {
    getIdToken(auth.currentUser, true).then(() => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const formattedUser = await formatAuthUser(user);
          localStorage.setItem("authUser", JSON.stringify(formattedUser));
          api.defaults.headers.Authorization = `Bearer ${formattedUser.token}`;
        }
      });
    });
  }
};

const onResponseError = (
  error: AxiosError<ErrorResponseType>
): Promise<AxiosError<ErrorResponseType>> => {
  console.log({ error });
  const status = error?.response?.status;

  if (status == 401) {
    Sentry.addBreadcrumb({
      category: "auth",
      message: "Refresh token",
      level: "info",
    });
    refreshToken();
    return Promise.reject(error);
  }

  if (status === 403) {
    Toast({
      title: "Error",
      message: "Your login session has expired. Please login again.",
    });

    Sentry.captureException(error);

    api.defaults.headers.Authorization = "";
    localStorage.removeItem("authUser");
    Router.router && Router.push("/login");

    return Promise.reject(
      error || { response: { status: 401, data: { message: "Unauthorized" } } }
    );
  }

  if (status === 500) {
    Router.router && Router.push("/500");
  }

  if (status == 404) {
    Router.router && Router.push("/404");
  }

  if (
    error?.response?.data?.inRange === undefined &&
    error?.message !== "Network Error"
  ) {
    Toast({
      title: "Error",
      message:
        error?.response?.data?.message ||
        error?.response?.data?.error ||
        "Ops, something went wrong.",
    });
  }

  return Promise.reject(
    error || {
      response: {
        status: 400,
        data: { message: "Ops, something went wrong." },
      },
    }
  );
};

api.interceptors.response.use(onResponse, onResponseError);

export default api;
