import styled from "styled-components";
import { IoIosArrowDown } from "react-icons/io";
import { Theme } from "styles/theme";
import { ArrowIconProps } from "./interface";

export const Wrapper = styled.div`
  margin-top: 24px;
  margin-left: -16px;
  width: calc(100% + 32px);

  @media (min-width: 768px) {
    margin-left: -24px;
    width: calc(100% + 24px);
  }
`;

export const Header = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-weight: 900;
  font-size: 16px;
  color: ${Theme.colors.tundora};
  margin-left: 16px;

  @media (min-width: 768px) {
    margin-left: 24px;
  }
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: ${Theme.colors.gray};
  margin-left: 16px;

  @media (min-width: 768px) {
    margin-left: 24px;
  }
`;

export const Tag = styled.div`
  height: 28px;
  padding: 8px;
  border-radius: 6px;
  background-color: ${Theme.colors.tundora};
  color: white;
  text-transform: uppercase;
  font-weight: 900;
  display: inline-block;
  margin-right: 8px;
`;

export const RightContent = styled.div`
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  padding: 16px;
  border-radius: 24px;
  width: 100%;
  min-height: 30px;
  background-color: white;
  margin-top: 8px;

  @media (min-width: 768px) {
    padding: 24px;
  }
`;

export const ArrowIcon = styled(IoIosArrowDown)<ArrowIconProps>`
  transition: all 0.2s ease-in;
  transform: ${(props) =>
    props.isOpen ? "rotate(-180deg);" : "rotate(0deg);"};
`;
