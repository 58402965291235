import { ButtonComplete, PaymentTitle } from "components/Cart/styles";
import styled from "styled-components";
import { Theme } from "styles/theme";

export const AddCardTitle = styled(PaymentTitle)`
  display: none;
  
  @media (min-width: 768px) {
    display: block;
  }
`;

export const FormCard = styled.div`
  margin-top: 2.5rem;
`;

export const FormRow = styled.div`
  margin-bottom: 3.2rem;

  input {
    &::placeholder {
      color: ${Theme.colors.alto};
    }
  }
`;

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
`;

export const ButtonSave = styled(ButtonComplete)``;
