import Script from "next/script";

interface Props {
  measureId: string;
}

export function GoogleAnalytics({ measureId }: Props) {
  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${measureId}`}
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${measureId}', {
                    page_path: window.location.pathname,
                });
                `,
        }}
      />
    </>
  );
}
