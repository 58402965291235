import { useMediaQuery } from "react-responsive";

import MagicVoucherModal from "components/MagicVoucherModal";
import MagicVouchersList from "components/MagicVouchersList";
import { MagicVoucherType } from "components/MagicVouchersList/interface";
import { Modal } from "components/Modal";
import useModal from "hooks/useModal";

import * as C from "components/Cart/styles";
import * as S from "./styles";

type MagicVouchersListCartProps = {
  closeVouchersMenu: () => void;
  onSetVoucher: (voucher: MagicVoucherType) => void;
};

const MagicVouchersListCart = ({
  closeVouchersMenu,
  onSetVoucher,
}: MagicVouchersListCartProps) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { open, toggle } = useModal();

  return (
    <C.CartContent>
      <C.MobileMerchantHeader>
        <h2>Magic vouchers</h2>

        <C.MobileCloseButton onClick={closeVouchersMenu}>
          <img src="/img/icons-close.svg" />
        </C.MobileCloseButton>
      </C.MobileMerchantHeader>

      <C.OverflowContent>
        <C.Section noBorderBottom>
          <S.ListTitle>Magic vouchers</S.ListTitle>

          <MagicVouchersList
            onSetVoucher={onSetVoucher}
            closeVouchersMenu={closeVouchersMenu}
            isCart
          />
        </C.Section>
      </C.OverflowContent>

      <C.ButtonWrapper>
        <S.ButtonNew onClick={() => toggle(true)}>
          Add magic voucher
        </S.ButtonNew>
      </C.ButtonWrapper>

      <Modal
        title=""
        open={open}
        onClose={() => toggle(false)}
        widthAuto={!isMobile}
      >
        <MagicVoucherModal
          isCart
          onClose={() => {
            toggle(false);
            closeVouchersMenu();
          }}
        />
      </Modal>
    </C.CartContent>
  );
};

export default MagicVouchersListCart;
