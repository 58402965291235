import { useCallback, useState } from "react";

import Button from "components/Button";
import { MagicVoucherType } from "components/MagicVouchersList/interface";
import TextField from "components/TextField";
import Toast from "components/Toast";
import { useAuth } from "contexts/AuthUserContext";
import useCart from "hooks/useCart";
import api from "services/api";

import * as S from "./styles";

type MagicVoucherModalProps = {
  onClose: () => void;
  voucherIdParam?: string;
  isCart?: boolean;
};

const MagicVoucherModal = ({ onClose, isCart, voucherIdParam }: MagicVoucherModalProps) => {
  const [voucherId, setVoucherId] = useState(voucherIdParam || "");
  const [isLoading, setIsLoading] = useState(false);
  const { authUser } = useAuth();
  const { setVouchers, setCart, setMagicVoucherAnimation } = useCart();

  const updateCartMagicVouchers = (data: MagicVoucherType) => {
    setCart((cartState) => ({
      ...cartState,
      magicVouchers: [...cartState.magicVouchers, data],
    }));
  };

  const handleSave = useCallback(async () => {
    try {
      setIsLoading(true);
      setMagicVoucherAnimation(true);
      setTimeout(() => {
        setMagicVoucherAnimation(false);
      }, 3000);

      const { data } = await api.post(
        `/users/${authUser?.uid}/vouchers/${voucherId}`
      );

      setVouchers((old) => [...old, data]);

      if (isCart) {
        updateCartMagicVouchers(data);
      }

      Toast({
        title: "Success",
        message: "Voucher added successfully",
        type: "success",
      });

      onClose();
    } finally {
      setIsLoading(false);
    }
  }, [authUser, voucherId]);

  return (
    <S.Wrapper>
      <S.Flex1>
        <S.Title>Add magic voucher</S.Title>

        <S.Description>
          Simply type or scan the Bento Magic Voucher. It works like magic.
        </S.Description>

        <S.Section>
          <TextField
            variant="material"
            placeholder="Magic Voucher Code"
            value={voucherId}
            onInput={(v) => setVoucherId(v as string)}
          />
        </S.Section>
      </S.Flex1>

      <S.ButtonWrapper>
        <Button
          onClick={handleSave}
          disabled={isLoading || !voucherId}
          loading={isLoading}
        >
          Save
        </Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default MagicVoucherModal;
