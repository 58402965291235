import styled, { css, keyframes } from "styled-components";
import { Theme } from "styles/theme";
import { SwitchProps } from ".";

type WrapperProps = Pick<SwitchProps, "checked" | "disabled">;

const switchOn = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1.1);
  }
`;

const switchOff = keyframes`
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export const Wrapper = styled.button<WrapperProps>`
  ${({ checked, disabled }) => css`
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 32px;
    height: 18px;
    line-height: 20px;
    padding: 0;
    vertical-align: middle;
    border-radius: 20px 20px;
    border: 1px solid transparent;
    background-color: rgba(197, 192, 192, 0.4);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);

    &:after {
      position: absolute;
      width: 14px;
      height: 14px;
      left: 2px;
      top: 1px;
      border-radius: 50% 50%;
      background-color: ${Theme.colors.gray};
      content: " ";
      cursor: pointer;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
      transform: scale(1);
      transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
      animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
      animation-duration: 0.3s;
      animation-name: ${switchOff};
    }

    &:hover:after {
      transform: scale(1.1);
      animation-name: ${switchOn};
    }

    &:focus {
      box-shadow: 0 0 0 2px tint(${Theme.colors.sunsetOrange}, 80%);
      outline: none;
    }

    ${checked &&
    css`
      ${Inner} {
        left: 6px;
      }

      &:after {
        left: 14px;
        background-color: ${Theme.colors.sunsetOrange};
      }
    `}

    ${disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;

      &:after {
        background-color: ${checked
          ? Theme.colors.sunsetOrange
          : Theme.colors.gray};
        animation-name: none;
        cursor: not-allowed;
      }

      &:hover:after {
        transform: scale(1);
        animation-name: none;
      }
    `}
  `}
`;

export const Inner = styled.div`
  color: "#fff";
  font-size: 1rem;
  position: absolute;
  left: 2.4rem;
  top: 0;
`;
