import { forwardRef, InputHTMLAttributes, useState } from "react";

import {
  creditCardAlterMask,
  creditCardMask,
  dateMonthYearMask,
  moneyMask,
  numberCleanMask,
  numberMask,
} from "utils/formats/masks";

import * as S from "./styles";

const masks = {
  money: (value: string) => moneyMask(value),
  creditCard: (value: string) => creditCardMask(value),
  dateMonthYear: (value: string) => dateMonthYearMask(value),
  creditCardAlter: (value: string) => creditCardAlterMask(value),
  number: (value: string, min?: number, max?: number) => {
    if (min && Number(value) < min) {
      value = String(min);
    }
    if (max && Number(value) > max) {
      value = String(max);
    }
    return numberMask(value);
  },
  numberClean: (value: string) => String(numberCleanMask(value)),
};

export type TextFieldProps = {
  onInput?: (value: string) => void;
  label?: string;
  labelFor?: string;
  initialValue?: string;
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
  disabled?: boolean;
  error?: string;
  variant?: "default" | "material";
  mask?:
    | "money"
    | "number"
    | "creditCard"
    | "creditCardAlter"
    | "dateMonthYear"
    | "numberClean";
  max?: number;
  min?: number;
  isRequired?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

const TextField: React.ForwardRefRenderFunction<
  HTMLInputElement,
  TextFieldProps
> = (
  {
    icon,
    iconPosition = "left",
    label,
    labelFor = "",
    initialValue = "",
    error,
    disabled = false,
    onInput,
    variant = "default",
    mask,
    max,
    min,
    isRequired,
    ...props
  },
  ref
) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const newValue = mask ? masks[mask](value, min, max) : value;
    setValue(newValue);
    !!onInput && onInput(newValue);
  };

  return (
    <S.Wrapper
      disabled={disabled}
      error={!!error}
      variant={variant}
      iconPosition={iconPosition}
      hasIcon={!!icon}
    >
      {!!label && (
        <S.Label htmlFor={labelFor}>
          {label} {!!isRequired && <span>*</span>}
        </S.Label>
      )}
      <S.InputWrapper>
        {!!icon && <S.Icon iconPosition={iconPosition}>{icon}</S.Icon>}
        <S.Input
          type="text"
          onChange={onChange}
          value={value}
          iconPosition={iconPosition}
          disabled={disabled}
          ref={ref}
          minLength={min}
          {...props}
        />
      </S.InputWrapper>
      {!!error && <S.Error>{error}</S.Error>}
    </S.Wrapper>
  );
};

export default forwardRef(TextField);
