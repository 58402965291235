import { darken } from "polished";
import styled from "styled-components";
import { Theme } from "styles/theme";
import SelectButton from "components/SelectButton";

export const Delivery = styled.div``;

export const DeliveryChoose = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    margin-bottom: 0;
    font-family: "Nunito";
    font-size: 1.6rem;
    font-weight: bold;
    color: ${Theme.colors.tundora};
  }
`;
export const DeliverySelectButton = styled(SelectButton)`
  &:first-child {
    margin-right: 0.8rem;
  }

  @media (min-width: 768px) {
    min-width: 20rem;
  }
`;

export const DeliveryAddress = styled.div`
  margin: 1.6rem 0 0;
  display: flex;
  border-top: 1px solid ${Theme.colors.wildSand};
  width: 100%;

  /* @media (min-width: 768px) {
    width: 41.6rem;
  } */
`;

export const AddAddress = styled.button`
  padding: 1.6rem 0 1.6rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: none;
  background-color: transparent;
  cursor: pointer;

  p {
    font-family: "Nunito";
    font-weight: 600;
    font-size: 1.4rem;
    color: ${Theme.colors.sunsetOrange};
    transition: color 0.2s;
  }

  &:hover {
    p {
      color: ${darken(0.15, Theme.colors.sunsetOrange)};
    }
  }
`;

export const RightIcon = styled.img`
  margin-left: 1.6rem;
  height: 1.6rem;
`;
