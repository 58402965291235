import { useCallback, useEffect, useState } from "react";

import Button from "components/Button";
import TextField from "components/TextField";
import Toast from "components/Toast";
import { useAuth } from "contexts/AuthUserContext";
import api from "services/api";

import * as S from "./styles";

type PhoneVarificationCodeModalProps = {
  phoneNumber: string;
  onClose: () => void;
};

const MINUTES = 1;

const PhoneVerificationCodeModal = ({
  phoneNumber,
  onClose,
}: PhoneVarificationCodeModalProps) => {
  const [verificationCode, setVerificationCode] = useState("");
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(true);
  const [showDissmiss, setShowDissmiss] = useState(false);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { authUser } = useAuth();

  const handleVerifyCode = useCallback(async () => {
    try {
      setIsLoading(true);

      const dataToSave = {
        phoneNumber,
        code: verificationCode,
      };

      await api.post(`/users/${authUser?.uid}/phone/verify`, dataToSave);

      Toast({
        title: "Success",
        message: "Phone verified successfully",
        type: "success",
      });
      onClose();
    } finally {
      setIsLoading(false);
    }
  }, [authUser, phoneNumber, verificationCode]);

  useEffect(() => {
    if (runTimer) {
      setCountDown(60 * MINUTES);
      setTimerId(
        setInterval(() => {
          setCountDown((old) => old - 1);
        }, 1000)
      );
    } else {
      clearInterval(timerId as NodeJS.Timeout);
      setTimerId(null);
    }

    return () => clearInterval(timerId as NodeJS.Timeout);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  useEffect(() => {
    if (countDown === 45) {
      setShowDissmiss(true);
    }
  }, [countDown]);

  const minutes = String(Math.floor(countDown / 60));
  const seconds = String(countDown % 60).padStart(2, "0");

  return (
    <S.Wrapper>
      <S.Flex1>
        <S.Title>Verification code</S.Title>

        <S.Description>
          You should receive a SMS with a verification code in a few seconds
        </S.Description>

        <S.Section>
          <TextField
            variant="material"
            placeholder="Verification code"
            value={verificationCode}
            onInput={(v) => setVerificationCode(v as string)}
            mask="numberClean"
          />
        </S.Section>

        <S.ResendWrapper>
          {runTimer && (
            <S.ResendTimer>
              Resend in {minutes}:{seconds}{" "}
              {Number(minutes) > 0 ? "minute" : "seconds"}
            </S.ResendTimer>
          )}
          {!runTimer && (
            <S.ButtonResend onClick={() => setRunTimer(true)} minimal>
              Resend
            </S.ButtonResend>
          )}

          {showDissmiss && (
            <S.ButtonResend
              onClick={onClose}
              minimal
              style={{
                marginTop: "2rem",
              }}
            >
              Continue without verifying
            </S.ButtonResend>
          )}
        </S.ResendWrapper>
      </S.Flex1>

      <S.ButtonWrapper>
        <Button color="white" onClick={onClose} disabled={isLoading} shadow>
          Cancel
        </Button>
        <Button
          onClick={handleVerifyCode}
          disabled={isLoading || !verificationCode}
          loading={isLoading}
        >
          Verify
        </Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default PhoneVerificationCodeModal;
