import styled from "styled-components";

export const Title = styled.h1`
  font-family: "Quicksand";
  font-size: 1.6rem;
  font-weight: 700;
  color: #1e1919;

  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Message = styled.p`
  font-family: "Quicksand";
  font-size: 1.4rem;
  font-weight: 500;
  white-space: pre-line;
  color: #1e1919;
  margin-top: 0.4rem;

  strong {
    font-weight: bold;
  }
`;

export const CloseButton = styled.div`
  margin: 0.6rem 0.2rem 0 0;
`;

export const ToastContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;
