import * as S from "./styles";

type AlertProps = {
  title: string;
  description?: string;
  style?: React.CSSProperties;
};

const Alert = ({ title, description, ...props }: AlertProps) => {
  return (
    <S.Wrapper {...props}>
      <S.Image src="/img/benny-assist.svg" />

      <S.Description>
        <h3>{title}</h3>
        {description && <p>{description}</p>}
      </S.Description>
    </S.Wrapper>
  );
};

export default Alert;
