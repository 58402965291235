import styled from "styled-components";
import { WrapperProps } from "./interface";

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;

  border: ${(props) => (props.border ? "1px solid #979797 " : "0")};
  height: ${(props) => (props.border ? "48px" : "auto")};
  border-radius: 12px;

  div {
    margin: ${(props) => (props.border ? "0 24px" : "0")};
  }

  svg {
    margin: 0 8px;
    margin: ${(props) => (props.border ? "0 12px" : "0 8px")};
    cursor: pointer;
  }
`;

export const Value = styled.div`
  font-family: "Quicksand";
  font-size: 16px;
  font-weight: 600;
`;
