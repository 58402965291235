export const Theme = {
  colors: {
    sunsetOrange: "#ff4c4c",
    tundora: "#433d3d",
    gray: "#8e8b8b",
    grayDark: "#54545a",
    alto: "#dcdcdc",
    silver: "#c5c0c0",
    wildSand: "#f6f6f6",
    yellowOrange: "#fba240",
    bentoYellow: "#FBA240",
  },
};
