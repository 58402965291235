import * as S from './styles'

export type EmptyProps = {
  image: string
  title?: string
  description?: string
}

const Empty = ({ image, title, description }: EmptyProps) => (
  <S.Wrapper>
    <S.Image src={image} />
    {!!title && <S.Title>{title}</S.Title>}
    {!!description && <S.Description>{description}</S.Description>}
  </S.Wrapper>
)

export default Empty
