import { InputWrapper } from "components/TextField/styles";
import styled from "styled-components";
import { Theme } from "styles/theme";

export const Wrapper = styled.div`
  min-width: 60rem;
  padding: 2.4rem;

  display: flex;
  flex-direction: column;
  height: 100%;

  .tooltip {
    padding: 0.3rem 0.6rem;
    border-radius: 0.8rem;
    font-family: "Quicksand";
    font-size: 1.2rem;
  }
`;

export const Flex1 = styled.div`
  flex: 1;
`;

export const Title = styled.div`
  font-family: "Quicksand";
  font-size: 3rem;
  font-weight: 700;
  color: ${Theme.colors.sunsetOrange};

  width: 100%;
  text-align: center;
`;

export const Section = styled.div`
  margin-top: 2.8rem;
  padding: 2.4rem;
  border-radius: 2.5rem;
  background-color: #ffffff;
`;

export const Search = styled.div`
  margin-bottom: 0.8rem;

  ${InputWrapper} {
    border: 2px solid #c5c0c0;
  }
`;

export const List = styled.ul`
  overflow-y: auto;
  list-style: none;
  height: 100vh;
  max-height: 58vh;
`;

export const ListItem = styled.li`
  display: flex;
  padding: 0.8rem;
  gap: 1.6rem;
  align-items: center;
  border-radius: 0.8rem;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const Emoji = styled.div`
  font-size: 4rem;
  line-height: 3.2rem;
`;

export const Box = styled.div``;

export const Description = styled.h6`
  font-family: "Nunito";
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: ${Theme.colors.tundora};
`;

export const DialCode = styled.p`
  font-family: "Quicksand";
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.2rem;
  color: ${Theme.colors.gray};
`;

export const NoResultsBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  max-height: 58vh;
`;

export const LoadingBox = styled(NoResultsBox)`
  align-items: initial;
`;
