import Button from "components/Button";
import {
  Animation as LoadingAnimation,
  Wrapper as LoadingWrapper,
} from "components/Loading/styles";
import SelectButton from "components/SelectButton";
import Image from "next/image";
import { darken } from "polished";
import styled, { css } from "styled-components";
import { Theme } from "styles/theme";

type WrapperProps = {
  isOpen?: boolean;
};

const wrapperModifiers = {
  open: () => css`
    transform: translateY(0);

    @media (min-width: 768px) {
      transform: translateX(0);
    }
  `,
  close: () => css`
    transform: translateY(calc(100vh + 700px));

    @media (min-width: 768px) {
      transform: translateX(700px);
    }
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ isOpen }) => css`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9000;

    transition: transform 0.2s ease-in;
    ${isOpen && wrapperModifiers.open()}
    ${!isOpen && wrapperModifiers.close()} 
    
    @media (min-width: 768px) {
      width: 660px;
      height: calc(100% - 112px);
      top: auto;
      left: auto;
      bottom: 0;
      right: 0;
    }
  `}
`;

export const CartContent = styled.div<{ noPadding?: boolean }>`
  ${({ noPadding = false }) => css`
    position: fixed;
    width: 100%;
    height: 100%;
    box-shadow: 0 9px 22px 0 rgba(0, 0, 0, 0.06);
    background-color: #fff;
    z-index: 9000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      width: 605px;
      height: 100%;
      padding: ${noPadding ? "0" : "3.2rem 0"};
      top: auto;
      left: auto;
      bottom: 0;
      right: 0;
    }
  `}
`;

export const EmptyCartContent = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 0 9px 22px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
  z-index: 9000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    width: 605px;
    height: 100%;
    padding: 3.2rem 0;
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
  }
`;

export const OverflowContent = styled.div`
  height: calc(100% - 18rem);
  overflow-y: auto;

  @media (min-width: 768px) {
    height: 85%;
  }
`;

const overlayModifiers = {
  open: () => css`
    pointer-events: auto;
    opacity: 1;
  `,
  close: () => css`
    pointer-events: none;
    opacity: 0;
  `,
};

export const Overlay = styled.div<WrapperProps>`
  ${({ isOpen }) => css`
    display: none;

    @media (min-width: 768px) {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 8999;
      display: block;

      transition: opacity 0.2s ease-in;
      ${isOpen && overlayModifiers.open()}
      ${!isOpen && overlayModifiers.close()}
    }
  `}
`;

export const MobileCloseButton = styled.button`
  margin-right: 1.6rem;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Theme.colors.wildSand};
  cursor: pointer;

  img {
    width: 2.4rem;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

export const CloseButton = styled.button`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: none;
  justify-content: center;
  align-items: center;
  z-index: 9001;
  background-color: #fff;
  box-shadow: 0 9px 22px 0 rgba(0, 0, 0, 0.06);
  cursor: pointer;

  @media (min-width: 768px) {
    display: flex;
  }
`;

export const Section = styled.div<{
  noBorderBottom?: boolean;
  noPaddingRight?: boolean;
}>`
  ${({ noBorderBottom = false, noPaddingRight = false }) => css`
    padding: 1.6rem 2rem;
    border-bottom: ${noBorderBottom
      ? "none"
      : `1px solid ${Theme.colors.wildSand}`};
    padding-right: ${noPaddingRight ? 0 : "2rem"};

    @media (min-width: 768px) {
      padding: 1.6rem 3.2rem;
      padding-right: ${noPaddingRight ? 0 : "3.2rem"};

      &:first-child {
        padding-top: 0;
      }
    }
  `}
`;

export const RightIcon = styled.img`
  margin-left: 1.6rem;
  height: 1.6rem;
`;

export const MerchantHeader = styled.div`
  margin-bottom: 1.2rem;

  h4 {
    display: none;
    margin-bottom: 1.3rem;
    font-family: "Nunito";
    font-size: 1.6rem;
    font-weight: normal;
    color: ${Theme.colors.gray};

    @media (min-width: 768px) {
      display: block;
    }
  }
`;

export const MobileMerchantHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2.6rem 0 2.4rem;
  background-color: ${Theme.colors.wildSand};

  h2 {
    display: flex;
    justify-content: center;
    flex: 1;
    font-family: "Nunito";
    font-size: 2.6rem;
    font-weight: bold;
    color: ${Theme.colors.sunsetOrange};
    margin-left: 2.8rem;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Merchant = styled.div`
  display: flex;
  align-items: center;

  img {
    height: 5.6rem;
  }

  h2 {
    margin-left: 1.6rem;
    font-family: "Nunito";
    font-size: 20px;
    font-weight: bold;
    color: ${Theme.colors.tundora};
  }
`;

export const Delivery = styled.div``;
export const DeliveryChoose = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    margin-bottom: 0;
    font-family: "Nunito";
    font-size: 1.6rem;
    font-weight: bold;
    color: ${Theme.colors.tundora};
  }
`;
export const DeliverySelectButton = styled(SelectButton)`
  &:first-child {
    margin-right: 0.8rem;
  }

  @media (min-width: 768px) {
    min-width: 20rem;
  }
`;

export const DeliveryAddress = styled.div`
  margin: 1.6rem 0 0;
  display: flex;
  border-top: 1px solid ${Theme.colors.wildSand};
  width: 100%;

  /* @media (min-width: 768px) {
    width: 41.6rem;
  } */
`;
export const Address = styled.button`
  padding: 1.6rem 0 1.6rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  background-color: transparent;
  cursor: pointer;

  p {
    font-family: "Quicksand";
    font-size: 1.5rem;
    color: ${Theme.colors.gray};
    transition: color 0.2s;

    strong {
      font-family: "Nunito";
      font-size: 1.6rem;
      font-weight: bold;
      color: ${Theme.colors.tundora};
      margin-right: 1.2rem;
    }
  }

  &:hover {
    p {
      color: ${darken(0.1, Theme.colors.gray)};
    }
  }
`;
export const AddressImage = styled.div`
  display: flex;
  align-items: center;
`;
export const AddAddress = styled.button`
  padding: 1.6rem 0 1.6rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: none;
  background-color: transparent;
  cursor: pointer;

  p {
    font-family: "Nunito";
    font-weight: 600;
    font-size: 1.4rem;
    color: ${Theme.colors.sunsetOrange};
    transition: color 0.2s;
  }

  &:hover {
    p {
      color: ${darken(0.15, Theme.colors.sunsetOrange)};
    }
  }
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  /* max-height: 10rem; */
`;
export const Item = styled.div`
  padding-bottom: 1.6rem;

  & + div {
    border-top: 1px solid ${Theme.colors.wildSand};
    padding-top: 1.6rem;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;
export const ItemData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    max-width: 260px;
  }

  h3 {
    font-family: "Nunito";
    font-size: 1.6rem;
    font-weight: bold;
    color: ${Theme.colors.tundora};
  }

  p {
    font-family: "Quicksand";
    font-size: 1.5rem;
    font-weight: normal;
    color: ${Theme.colors.gray};
  }

  @media (min-width: 768px) {
    > div {
      max-width: none;
    }
  }
`;
export const ItemActions = styled.div`
  display: flex;
  margin-top: 1.6rem;
`;
export const ButtonEdit = styled.button`
  font-family: "Nunito";
  font-size: 1.4rem;
  font-weight: 600;
  color: ${Theme.colors.sunsetOrange};
  background: none;
  border: none;
  cursor: pointer;
`;
export const ButtonRemove = styled.button`
  font-family: "Nunito";
  font-size: 1.4rem;
  font-weight: 600;
  color: ${Theme.colors.gray};
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 2.4rem;
`;

export const VerticalLine = styled.div`
  width: 0.1rem;
  height: 100%;
  background-color: ${Theme.colors.alto};
  margin: 0 0.8rem;
`;

export const Vauchers = styled.div`
  display: flex;
  overflow-x: auto;
`;
export const Vaucher = styled.div`
  display: flex;
  align-items: center;
  padding: 1.6rem;
  border-radius: 0.8rem;
  margin-right: 0.8rem;
  background-color: ${Theme.colors.wildSand};
  min-width: 23.2rem;
  max-width: 27rem;
`;
export const VaucherImage = styled.div<{ bg: string }>`
  width: 4.2rem;
  min-width: 4.2rem;
  height: 4.2rem;
  margin-right: 0.8rem;
  border-radius: 0.8rem;
  background-color: #d8d8d8;
  background-image: url(${(props) => props.bg});
  background-size: cover;
  background-position: center center;
`;
export const EmptyVaucher = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
export const EmptyVaucherImage = styled.div<{ bg: string }>`
  width: 6rem;
  height: 6rem;
  margin-right: 1.6rem;
  border-radius: 0.8rem;
  background-color: #d8d8d8;
  background-image: url(${(props) => props.bg});
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center center;
`;
export const EmptyBox = styled.div`
  display: flex;
  align-items: center;
`;
export const VaucherDesc = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  h4 {
    font-family: "Nunito";
    font-size: 1.6rem;
    font-weight: 800;
    color: ${Theme.colors.tundora};
  }

  p {
    font-family: "Quicksand";
    font-size: 1.5rem;
    font-weight: normal;
    color: ${Theme.colors.gray};
  }
`;
export const ButtonVaucher = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
`;
export const VaucherActions = styled.div`
  display: flex;
  align-items: center;
`;
export const VaucherDeleteButton = styled.button`
  background: ${Theme.colors.sunsetOrange};
  border: none;
  border-radius: 0.4rem;
  padding: 0.6rem 0.4rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    width: 1.2em;
    height: 1.3rem;
  }

  &:hover {
    background: ${darken(0.15, Theme.colors.sunsetOrange)};
  }
`;

export const Totals = styled.div``;
export const TotalItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-family: "Quicksand";
    font-size: 1.5rem;
    font-weight: normal;
    color: ${Theme.colors.gray};

    &.voucher {
      color: #169b29;
    }
  }

  h2 {
    font-family: "Nunito";
    font-size: 1.6rem;
    font-weight: bold;
    color: ${Theme.colors.tundora};
  }
`;

export const Payment = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const PaymentTitle = styled.h2`
  font-family: "Nunito";
  font-size: 1.6rem;
  font-weight: bold;
  color: ${Theme.colors.tundora};
`;
export const PaymentCard = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;

  p {
    font-family: "Quicksand";
    font-size: 1.5rem;
    font-weight: normal;
    color: ${Theme.colors.gray};
  }
`;
export const TextChange = styled.p`
  font-family: "Quicksand";
  font-size: 1.5rem;
  font-weight: 600 !important;
  color: ${Theme.colors.sunsetOrange} !important;
  transition: color 0.2s;
  margin-left: 0.8rem;

  &:hover {
    color: ${darken(0.15, Theme.colors.sunsetOrange)};
  }
`;
export const AddPaymentCard = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;

  p {
    font-family: "Quicksand";
    font-size: 1.5rem;
    font-weight: 600;
    color: ${Theme.colors.sunsetOrange};
    transition: color 0.2s;
  }

  &:hover {
    p {
      color: ${darken(0.15, Theme.colors.sunsetOrange)};
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${Theme.colors.wildSand};
  flex-direction: column;
`;

export const ButtonComplete = styled(Button)`
  font-family: "Nunito";
  font-size: 1.4rem;
  font-weight: bold;
  color: #fff;
  padding: 1.4rem 8.2rem;
  margin: 2.6rem 0;
  background-color: ${Theme.colors.sunsetOrange};
  border: none;
  cursor: pointer;
  border-radius: 2.4rem;
  transition: background 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);

  @media (min-width: 768px) {
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${darken(0.25, Theme.colors.sunsetOrange)};
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
    background-color: ${Theme.colors.silver};

    &:hover {
      background-color: ${Theme.colors.silver};
    }
  }
`;

export const MerchantLogo = styled(Image)`
  max-width: 60px;
  min-width: 60px;
  object-fit: scale-down;
`;

export const LoadingContainer = styled.div`
  ${() => css`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9010;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    ${LoadingWrapper} {
      height: 85vh;
    }

    ${LoadingAnimation} {
      width: 5rem;
      height: 5rem;
      border-width: 0.6rem;
      z-index: 9011;
    }

    @media (min-width: 768px) {
      width: 605px;
      height: 100%;
      top: auto;
      left: auto;
      bottom: 0;
      right: 0;
    }
  `}
`;

export const LoadingOverlay = styled.div`
  ${() => css`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 9010;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.8;

    @media (min-width: 768px) {
      width: 605px;
      height: 100%;
      top: auto;
      left: auto;
      bottom: 0;
      right: 0;
    }
  `}
`;

export const MinimumDelivery = styled.div`
  display: block;
  color: ${Theme.colors.sunsetOrange};
  margin-top: 16px;
  font-size: 1.4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  max-width: 400px;
  svg {
    margin: 5px;
    width: 24px;
  }
`;

export const MagicVoucherContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  img {
    position: absolute;
    top: calc(50% - 75px);
    left: calc(50% - 85px);
    width: 150px;
  }

  span {
    animation: blinker 1s linear infinite;
    font-family: "Quicksand";
    font-size: 3rem;
    font-weight: 600;
    position: absolute;
    top: calc(65%);
    left: calc(50% - 136px);
    z-index: 4000;
    color: white;

    @media (min-width: 768px) {
      top: calc(70%);
    }
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`;

export const MagicVoucherPanel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(30, 30, 30, 0.8);
  width: 100%;
  height: 100%;
  z-index: 9200;
`;
