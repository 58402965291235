import styled from "styled-components";
import { Theme } from "styles/theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
  border-radius: 0;

  @media (min-width: 768px) {
    border-radius: 24px;
  }
`;

export const OverflowWrapper = styled.div`
  max-height: 60vh;
  overflow: auto;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 16px 24px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 16px 24px;
`;

export const Title = styled.div`
  font-family: "Quicksand";
  font-size: 2.4rem;
  font-weight: 700;
  color: ${Theme.colors.sunsetOrange};
`;

export const Valid = styled.div`
  font-family: "Quicksand";
  color: #99999c;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

export const Description = styled.div`
  font-family: "Quicksand";
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.22;
  color: ${Theme.colors.gray};
  margin-top: 1rem;
  padding: 16px 24px;
  display: flex;
  width: 100%;
  max-width: 577px;
  white-space: pre-wrap;
`;

export const QRContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 577px;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  gap: 16px;
  font-weight: 700;
  color: #000;
`;

export const Section = styled.div`
  margin-top: 2.8rem;
  padding: 2.4rem;
  border-radius: 2.5rem;
  background-color: #ffffff;
`;

export const ButtonWrapper = styled.div`
  margin-top: 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.div`
  width: 100%;
  height: 187px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("/img/voucher-background.svg") center center no-repeat #ff4c4c;
  border-top: 1px solid #ff4c4c;
  border-left: 1px solid #ff4c4c;
  border-right: 1px solid #ff4c4c;
  border-bottom: none;
  top: 1px;
  position: relative;

  @media (min-width: 768px) {
    width: 577px;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
  }
`;

export const InsideImage = styled.img`
  position: relative;
  top: 30px;
`;

export const MagicVoucherPanel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(30, 30, 30, 0.8);
  width: 100vw;
  height: 100vh;
`;

export const CloseButton = styled.button`
  color: ${Theme.colors.sunsetOrange};
  font-size: 24px;
  line-height: 18px;
  background: none;
  border: 0;
  outline: none;
  position: absolute;
  right: 16px;
  top: 22px;
  cursor: pointer;
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 9999;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    right: 20px;
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
`;

export const ItemName = styled.div`
  display: flex;
  color: #39393f;
  font-weight: 700;
  font-size: 1.4rem;
`;

export const ItemPrice = styled.div`
  display: flex;
  color: #39393f;
  font-weight: 500;
  font-size: 1.2rem;
`;
