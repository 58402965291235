import { InputHTMLAttributes } from "react";
import { RadioProps } from "./interface";

import * as S from "./styles";

const Radio = ({
  label,
  onCheck,
  labelFor = "",
  value,
  disabled = false,
  ...props
}: RadioProps) => {
  const onChange = () => {
    !!onCheck && onCheck(value);
  };

  return (
    <S.Wrapper>
      <S.Input
        id={labelFor}
        type="radio"
        value={value}
        onChange={onChange}
        disabled={disabled}
        {...props}
      />
      {!!label && (
        <S.Label htmlFor={labelFor} disabled={disabled}>
          {label}
        </S.Label>
      )}
    </S.Wrapper>
  );
};

export default Radio;
