import styled, { css, keyframes } from "styled-components";
import { Theme } from "styles/theme";
import { ModalProps } from ".";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
`;

const fadeout = keyframes`
  from {
    opacity: 0.8;
  }
  to {
    opacity: 0;
  }
`;

const scaleIn = keyframes`
  from {
    transform: translate(-50%, -50%) scale(0.9);
  }
  to {
    transform: translate(-50%, -50%) scale(1);
  }
`;

const scaleOut = keyframes`
  from {
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    transform: translate(-50%, -50%) scale(0.9);
  }
`;

type isOpenType = Pick<ModalProps, "open">;
type WrapperType = { widthAuto: boolean } & isOpenType;

export const Wrapper = styled.div<WrapperType>`
  ${({ theme, open, widthAuto }) => css`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9101;
    width: 70rem;
    height: 90vh;
    outline: 0;
    border-radius: 2.4rem;

    ${open &&
    css`
      animation: ${scaleIn} 200ms ease-out;
    `}

    ${!open &&
    css`
      animation: ${scaleOut} 200ms ease-out;
    `}

    ${widthAuto &&
    css`
      width: fit-content;
      height: auto;

      ${ModalWrapper} {
        height: auto;
      }
    `}

    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
    }
  `}
`;

export const Overlay = styled.div<isOpenType>`
  ${({ theme, open }) => css`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9001;
    width: 100vw;
    height: 100vh;
    background-color: ${Theme.colors.tundora};
    opacity: 0.8;

    animation: ${open ? fadeIn : fadeout} 200ms ease-out;
  `}
`;

export const ModalWrapper = styled.div`
  ${({ theme }) => css`
    z-index: 9000;
    background: ${Theme.colors.wildSand};
    position: relative;
    border-radius: 2.4rem;
    box-shadow: 0 0.6rem 1.8rem 0 rgb(0 0 0 / 20%);
    height: 90vh;

    @media (max-width: 768px) {
      height: 100%;
      border-radius: 0;
    }
  `}
`;

export const Header = styled.header`
  ${({ theme }) => css`
    border-radius: 2.4rem;
    box-shadow: 0 0.6rem 1.8rem 0 rgb(0 0 0 / 10%);
    z-index: 1;
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: "Nunito";
    color: ${Theme.colors.sunsetOrange};
    font-size: calc(2.8rem - 0.2rem);
    font-weight: bold;
    text-align: center;
    flex: 1;
  `}
`;

export const CloseButton = styled.button`
  color: ${Theme.colors.sunsetOrange};
  font-size: 24px;
  line-height: 18px;
  background: none;
  border: 0;
  outline: none;
  position: absolute;
  right: 16px;
  top: 22px;
  cursor: pointer;
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    right: 20px;
  }
`;

export const Content = styled.main`
  height: calc(100%);
  display: flex;
  flex-direction: column;
`;

export const ModalOverflow = styled.div`
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 1.6rem calc(3.2rem - 1rem) 1.6rem 3.2rem;
`;

export const ModalFooter = styled.footer`
  flex: 0;
  background-color: #fff;
  padding: 2.4rem 3.2rem;
  border-radius: 2.4rem;
  box-shadow: 0 -0.6rem 1.8rem 0 rgb(0 0 0 / 10%);
  z-index: 1;
`;
