import { formatCurrency, formatNumber } from "./number";

export const phoneMask = (value: string) => {
  return value
    .replace(/\D+/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(\d{4})-(\d)(\d{4})/, "$1$2-$3")
    .replace(/(-\d{4})\d+?/, "$1");
};

export const dateMonthYearMask = (value: string) => {
  return value
    .replace(/\D+/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\/\d{4})\d+?/, "$1");
};

export const creditCardMask = (value: string) => {
  return value
    .replace(/\D+/g, "")
    .replace(/(\d{4})(\d)/, "$1 $2")
    .replace(/(\d{4})(\d)/, "$1 $2")
    .replace(/(\d{4})(\d)/, "$1 $2")
    .replace(/( \d{4})\d+?/, "$1");
};

export const creditCardAlterMask = (value: string) => {
  return value
    .replace(/\D+/g, "")
    .replace(/(\d{4})(\d)/, "$1 $2")
    .replace(/(\d{6})(\d)/, "$1 $2")
    .replace(/( \d{6})\d+?/, "$1");
};

export const moneyMask = (value: string) => {
  const cleanValue = +value.replace(/\D+/g, "");
  return formatCurrency(cleanValue / 100);
};

export const numberMask = (value: string) => {
  const cleanValue = +value.replace(/\D+/g, "");
  return formatNumber(cleanValue);
};

export const numberCleanMask = (value: string) => {
  return value.replace(/\D+/g, "");
};
