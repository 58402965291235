import { darken } from 'polished';
import styled from 'styled-components';
import { Theme } from 'styles/theme';
import { ContentStyleProps } from './interface';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100vh;
  flex-direction: column;
  padding-bottom: 105px;

  @media (min-width: 768px) {
    height: 85vh;
    padding-bottom: 0;
  }
`;

export const LoadWrapper = styled.div`
  width: 400px;
  display: flex;
  height: 100vh;
  flex-direction: column;
  padding-bottom: 105px;

  @media (min-width: 768px) {
    height: auto;
    width: 100%;
    padding-bottom: 0;
  }
`;

export const ItemImage = styled.img`
  width: 100%;
  min-width: 350px;
  height: 35vh;
  object-fit: cover;
  object-position: center;
  margin: 0;

  @media (min-width: 768px) {
    height: 30vh;
    border-top-right-radius: 2.4rem;
    border-top-left-radius: 2.4rem;
  }
`;

export const Content = styled.div<ContentStyleProps>`
  padding: 56px 32px 105px 32px;
  height: 55vh;
  overflow-x: hidden;
  width: 100%;
  flex: 1;

  @media (min-width: 768px) {
    min-width: 784px;
    padding: 56px 32px 0px 56px;
    max-height: ${(props) => (props.hasImage ? '55vh' : '80vh')};
  }
`;

export const Title = styled.h3`
  font-family: 'Quicksand';
  font-size: 30px;
  font-weight: 900;
  color: ${Theme.colors.sunsetOrange};
`;

export const Description = styled.div`
  font-family: 'Quicksand';
  font-size: 18px;
  font-weight: 600;
  color: ${Theme.colors.gray};
  margin-top: 4px;
  max-width: 700px;
  white-space: pre-line;
`;

export const Price = styled.div`
  font-family: 'Quicksand';
  font-size: 18px;
  font-weight: 900;
  margin-top: 20px;
  color: ${Theme.colors.tundora};
`;

export const ItemPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const DiscountItemTag = styled.div`
  background-color: #fba240;
  height: 22px;
  max-width: 190px;
  width: 100%;
  padding: 0 8px;
  margin-top: 20px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    font-family: 'Quicksand';
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
  }

  > span {
    position: absolute;
    top: 0;
    right: -3.66px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: inherit;

    &:nth-child(3) {
      top: 7.33px;
    }

    &:nth-child(4) {
      top: 14.66px;
    }
  }
`;

export const Button = styled.button`
  background-color: ${Theme.colors.sunsetOrange};
  font-family: 'Nunito';
  font-weight: bold;
  color: white;
  padding: 8px 32px;
  height: 48px;
  font-size: 1.4rem;
  border-radius: 32px;
  border: none;
  outline: none;
  cursor: pointer;

  @media (min-width: 768px) {
    font-size: 1.6rem;
    padding: 13px 32px;
  }

  &:disabled {
    background-color: ${Theme.colors.gray};
    color: white;
  }
`;

export const Footer = styled.div`
  display: flex;
  padding: 24px 16px 35px;
  justify-content: space-between;
  height: 100px;
  position: fixed;
  bottom: 0;
  background-color: ${Theme.colors.wildSand};
  width: 100%;
  z-index: 9000;

  @media (min-width: 768px) {
    padding: 32px;
    position: static;
    bottom: auto;
    border-radius: 2.4rem;
    height: 105px;
  }

  ${Button} {
    margin-left: 16px;
  }
`;

export const ItemPrice = styled.div`
  font-family: 'Quicksand';
  font-size: 18px;
  font-weight: 900;
  margin-top: 20px;
  color: ${Theme.colors.tundora};
  display: flex;
`;

export const ItemPriceOverline = styled.div`
  text-decoration: line-through;
  font-family: 'Quicksand';
  font-size: 18px;
  font-weight: 900;
  color: ${Theme.colors.gray};
`;

export const ItemPriceDiscount = styled.div`
  color: #43cb8f;
  font-family: 'Quicksand';
  font-size: 18px;
  font-weight: 900;
  margin-right: 8px;
  color: #43cb8f;
`;

export const ConfirmTitle = styled.h1`
  font-size: 2rem;
  font-weight: bold;
`;

export const ConfirmDescription = styled.p`
  margin-top: 16px;
  font-size: 1.7rem;
  font-weight: normal;
`;

export const Container = styled.div`
  padding: 3rem;
`;

export const MerchantName = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0.4rem 0 1rem 0;
  cursor: pointer;

  p {
    font-family: 'Quicksand';
    font-size: 1.5rem;
    font-weight: 600;
    color: ${Theme.colors.sunsetOrange};
    transition: color 0.2s;
  }

  &:hover {
    p {
      color: ${darken(0.15, Theme.colors.sunsetOrange)};
    }
  }
`;

export const RightIcon = styled.img`
  margin-left: 1.6rem;
  height: 1.6rem;
`;
