import AddressList from "components/AddressList";
import { AddressType } from "components/Cart/interface";
import * as C from "components/Cart/styles";
import { Dispatch, SetStateAction, useState } from "react";
import * as S from "./styles";

type AddressListProps = {
  items: AddressType[];
  handleCloseAddresses: Dispatch<SetStateAction<boolean>>;
  handleOpenNewAddress: Dispatch<SetStateAction<boolean>>;
  onSetAddress: (address: AddressType | null) => void;
  onEditAddress: (address: AddressType | null) => void;
};

const AddressListCart = ({
  items,
  handleCloseAddresses,
  handleOpenNewAddress,
  onSetAddress,
  onEditAddress,
}: AddressListProps) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <C.CartContent>
      <C.MobileMerchantHeader>
        <h2>Addresses</h2>

        <C.MobileCloseButton onClick={() => handleCloseAddresses(false)}>
          <img src="/img/icons-close.svg" />
        </C.MobileCloseButton>
      </C.MobileMerchantHeader>

      <C.OverflowContent>
        <C.Section noBorderBottom>
          <S.ListTitle>Addresses</S.ListTitle>

          <AddressList
            items={items}
            handleOpenNewAddress={handleOpenNewAddress}
            handleCloseAddresses={handleCloseAddresses}
            onSetAddress={onSetAddress}
            onEditAddress={onEditAddress}
            onLoading={setIsLoading}
          />
        </C.Section>
      </C.OverflowContent>

      <C.ButtonWrapper>
        <S.ButtonNew
          onClick={() => {
            onEditAddress(null);
            handleOpenNewAddress(true);
          }}
          disabled={isLoading}
        >
          New address
        </S.ButtonNew>
      </C.ButtonWrapper>
    </C.CartContent>
  );
};

export default AddressListCart;
