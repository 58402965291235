import { CardType } from "components/CardList/interface";
import { MagicVoucherType } from "components/MagicVouchersList/interface";

export interface CartType {
  merchant: MerchantType | null;
  items: Item[];
  type: string;
  magicVouchers: MagicVoucherType[];
  total: number;
  subTotal: number;
  deliveryFee: number | null;
  address: AddressType | null;
  pickupAddress?: AddressType | null;
  paymentMethod: CardType | null;
  isOffline?: boolean;
}

export interface Price {
  menuId: string;
  price: number;
}

export interface ModifierOption {
  itemId: string;
  name: string;
  price: number;
  qty: number;
  available: boolean;
}

export interface Modifier {
  options: ModifierOption[];
  min: number;
  max: number;
  modifierId: string;
  name: string;
  maxEachOption: number;
  merchant: string;
}

export interface Item {
  itemDummyId?: string;
  menus: string[];
  name: string;
  prices: Price[];
  itemId: string;
  description: string;
  price: number;
  qty: number;
  featuredPicture: string;
  merchant: string | MerchantType;
  modifiers: Modifier[];
  categories: string[];
  available: boolean;
  isSellable: boolean;
  comments?: string;
}

export interface AddressType {
  description: string;
  city: string;
  country: string;
  streetName: string;
  streetNumber: string;
  instructions: string;
  extraInfo: string;
  isDefault: boolean;
  coordinates: CoordinatesType;
  coordinatesAdjustment: CoordinatesType;
}

export interface User {
  uuid: string;
  email: string;
  displayName: string;
  name: string;
  phoneNumber?: string;
  token?: string;
  platform: string;
  version: string;
  locked: true;
  merchants: string[];
}

export interface DeliveryFeeType {
  addressFrom: {
    coordinates: CoordinatesType;
  };
  addressTo: {
    coordinates: CoordinatesType;
    coordinatesAdjustment: CoordinatesType;
  };
  merchant: {
    id: string;
  };
  user: Pick<User, "uuid" | "name" | "email" | "phoneNumber" | "token"> & {
    merchantId: string;
  };
}

export enum MerchantServicesAvailable {
  takeout = "takeout",
  delivery = "delivery",
  voucher = "voucher",
  donation = "donation",
}

export interface MerchantType {
  id: string;
  name: string;
  logo: string;
  slug: string;
  coordinates?: CoordinatesType;
  address?: AddressType;
  warningMessage?: string;
  hasTakeout?: boolean;
  hasDelivery?: boolean;
  servicesAvailable?: MerchantServicesAvailable[];
}

export interface CoordinatesType {
  lat: number | undefined;
  lng: number | undefined;
}
