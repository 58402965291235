import styled, { css } from "styled-components";
import { lighten } from "polished";
import { Theme } from "styles/theme";

export const Wrapper = styled.button<{ selected: boolean }>`
  ${({ selected }) => css`
    font-family: "Quicksand";
    border: none;
    border: 1px solid ${Theme.colors.gray};
    color: ${Theme.colors.gray};
    border-radius: 2.4rem;
    font-weight: 600;
    font-size: 1.6rem;
    padding: 1.2rem 2rem calc(1.6rem - 0.3rem);
    background-color: transparent;
    cursor: pointer;
    margin-right: 2.4rem;
    min-width: 7.6rem;
    transition: all 0.2s;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: ${Theme.colors.wildSand};
    }

    ${selected &&
    css`
      background-color: ${lighten(0.3, Theme.colors.sunsetOrange)};
      color: ${Theme.colors.sunsetOrange};
      border-color: ${lighten(0.3, Theme.colors.sunsetOrange)};

      &:hover {
        background-color: ${lighten(0.25, Theme.colors.sunsetOrange)};
        color: ${Theme.colors.sunsetOrange};
        border-color: ${lighten(0.3, Theme.colors.sunsetOrange)};
      }
    `}

    @media (min-width: 768px) {
      margin-right: 0.8rem;
    }
  `}
`;
