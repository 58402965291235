import { useState } from 'react'

import * as S from './styles'

export type SwitchChangeEventHandler = (
  checked: boolean,
  event: React.MouseEvent<HTMLButtonElement>
) => void
export type SwitchClickEventHandler = SwitchChangeEventHandler

export type SwitchProps = {
  disabled?: boolean
  checkedChildren?: React.ReactNode
  unCheckedChildren?: React.ReactNode
  onChange?: SwitchChangeEventHandler
  onClick?: SwitchClickEventHandler
  tabIndex?: number
  checked?: boolean
  defaultChecked?: boolean
} & Omit<React.HTMLAttributes<HTMLButtonElement>, 'onChange' | 'onClick'>

const Switch = ({
  checked,
  defaultChecked,
  disabled = false,
  checkedChildren,
  unCheckedChildren,
  onClick,
  onChange,
  ...props
}: SwitchProps) => {
  const [innerChecked, setInnerChecked] = useState<boolean>(
    checked || defaultChecked || false
  )

  const triggerChange = (
    newChecked: boolean,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    let mergedChecked = innerChecked

    if (!disabled) {
      mergedChecked = newChecked
      setInnerChecked(mergedChecked)
      onChange?.(mergedChecked, event)
    }

    return mergedChecked
  }

  const onInternalClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const ret = triggerChange(!innerChecked, e)
    onClick?.(ret, e)
  }

  return (
    <S.Wrapper
      {...props}
      type="button"
      role="switch"
      aria-checked={innerChecked}
      disabled={disabled}
      onClick={onInternalClick}
      checked={innerChecked}
    >
      <S.Inner>{innerChecked ? checkedChildren : unCheckedChildren}</S.Inner>
    </S.Wrapper>
  )
}

export default Switch
