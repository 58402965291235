import styled from "styled-components";
import { Theme } from "styles/theme";

export const Wrapper = styled.div`
  text-align: center;
`;

export const Image = styled.img`
  margin-bottom: 1rem;
  width: 6.1rem;
`;

export const Title = styled.h4`
  font-weight: 600;
  font-size: 2rem;
`;

export const Description = styled.p`
  font-family: "Nunito";
  font-size: 2rem;
  color: ${Theme.colors.gray};
`;
