export const formatCurrencyWithSymbol = (
  value: number,
  locale = 'en-KY',
  currency = 'KYD'
) => {
  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value)
}

export const formatCurrencyWithCode = (
  value: number,
  locale = 'en-KY',
  currency = 'KYD'
) => {
  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'code',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value)
}

export const formatCurrency = (
  value: number,
  locale = 'en-KY',
  currency = 'KYD'
) => {
  return Intl.NumberFormat(locale, {
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value)
}

export const formatNumber = (value: number, locale = 'en-KY') => {
  return Intl.NumberFormat(locale, {
    maximumFractionDigits: 2
  }).format(value)
}

export const formatPercentage = (value: number, locale = 'en-KY') => {
  return Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value)
}
