import { AnchorHTMLAttributes, ButtonHTMLAttributes, forwardRef } from "react";

import { Animation as LoadingAnimation } from "../Loading/styles";

import * as S from "./styles";

type ButtonTypes =
  | AnchorHTMLAttributes<HTMLAnchorElement>
  | ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonProps = {
  children?: React.ReactNode;
  color?: "primary" | "white";
  size?: "small" | "medium" | "large";
  fullWidth?: boolean;
  minimal?: boolean;
  loading?: boolean;
  shadow?: boolean;
  disabled?: boolean;
  icon?: JSX.Element;
  iconSide?: "left" | "right";
  iconButton?: boolean;
  as?: React.ElementType;
} & ButtonTypes;

const Button: React.ForwardRefRenderFunction<S.WrapperProps, ButtonProps> = (
  {
    children,
    color = "primary",
    icon,
    iconSide = "left",
    iconButton = false,
    size = "medium",
    fullWidth = false,
    minimal = false,
    loading = false,
    shadow = false,
    disabled = false,
    ...props
  },
  ref
) => (
  <S.Wrapper
    color={color}
    size={size}
    fullWidth={fullWidth}
    iconSide={iconSide}
    iconButton={iconButton}
    hasIcon={!!icon}
    minimal={minimal}
    isLoading={loading}
    hasShadow={shadow}
    disabled={disabled}
    ref={ref}
    {...props}
  >
    {loading && <LoadingAnimation />}
    {!!icon && !loading && icon}
    {!!children && <span>{children}</span>}
  </S.Wrapper>
);

export default forwardRef(Button);
