import styled, { keyframes } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95vh;
`;

const load8 = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Animation = styled.div`
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  position: relative;
  border-top: 0.3rem solid rgba(255, 76, 76, 0.2);
  border-right: 0.3rem solid rgba(255, 76, 76, 0.2);
  border-bottom: 0.3rem solid rgba(255, 76, 76, 0.2);
  border-left: 0.3rem solid #ff4c4c;
  transform: translateZ(0);
  animation: ${load8} 1.1s infinite linear;

  &:after {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
  }
`;

export const AnimationWhite = styled.div`
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  position: relative;
  border-top: 0.3rem solid rgba(255, 76, 76, 0.2);
  border-right: 0.3rem solid rgba(255, 76, 76, 0.2);
  border-bottom: 0.3rem solid rgba(255, 76, 76, 0.2);
  border-left: 0.3rem solid #fff2f2;
  transform: translateZ(0);
  animation: ${load8} 1.1s infinite linear;

  &:after {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
  }
`;
