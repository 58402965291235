import { isFunction } from "lodash";
import { useEffect, useState } from "react";

const useModal = () => {
  const [open, setOpen] = useState(false);

  const toggle = (close: boolean) => {
    if (!close) {
      const main = document.querySelector(".mainwrapper") as HTMLElement;
      if (main) main.style.overflow = "auto";
    }
    setOpen(close);
  };

  useEffect(() => {
    const closeWithEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        toggle(false);
      }
    };
    window.addEventListener("keydown", closeWithEscape);
    return () => window.removeEventListener("keydown", closeWithEscape);
  }, []);

  return {
    open,
    toggle,
  };
};

export default useModal;
