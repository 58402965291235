import styled from "styled-components";
import { Theme } from "styles/theme";

export const Wrapper = styled.div`
  padding: 2.4rem;

  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Flex1 = styled.div`
  flex: 1;
`;

export const Title = styled.div`
  font-family: "Quicksand";
  font-size: 3rem;
  font-weight: 700;
  color: ${Theme.colors.sunsetOrange};

  width: 100%;
  text-align: center;
`;

export const Description = styled.div`
  font-family: "Quicksand";
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.22;
  color: ${Theme.colors.gray};
  margin-top: 1.6rem;
`;

export const Section = styled.div`
  margin-top: 2.8rem;
  padding: 2.4rem;
  border-radius: 2.5rem;
  background-color: #ffffff;

  display: flex;
  gap: 2rem;
`;

export const ButtonWrapper = styled.div`
  margin-top: 2.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SelectDialCode = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  width: 17rem;
  border-bottom: 1px solid #979797;
  font-size: 1.6rem;
  color: #8e8b8b;
  padding-bottom: 0.8rem;
  font-weight: 500;
  cursor: pointer;
`;

export const FlagEmoji = styled.div`
  font-size: 2.4rem;
  line-height: 1.2rem;
`;

export const Icon = styled.img`
  margin-left: auto;
  margin-right: 0.2rem;
`;
