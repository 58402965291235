import Toast from "components/Toast";
import { useAuth } from "contexts/AuthUserContext";
import { add, compareAsc } from "date-fns";
import useCart from "hooks/useCart";
import { useEffect, useState } from "react";
import api from "services/api";

const WarningMessage = () => {
  const { bentoSettings } = useCart();
  const [, setHasWarningMessage] = useState(false);
  const { authUser } = useAuth();

  useEffect(() => {
    if (bentoSettings && bentoSettings?.warningMessage.length > 0) {
      setHasWarningMessage(true);

      if (location.pathname === "/") {
        Toast({
          title: "Hi! This is BennyBag",
          message: bentoSettings?.warningMessage,
          type: "info",
        });
      }
    }
  }, [bentoSettings]);

  return <></>;
};

export default WarningMessage;
