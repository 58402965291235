import { AddressType } from "components/Cart/interface";
import * as S from "./styles";

type AddressItemProps = {
  onClick: () => void;
  isBentoPackage: boolean;
  address: AddressType;
  label: string;
};

const AddressItem = ({
  onClick,
  isBentoPackage,
  address,
  label,
  ...props
}: AddressItemProps) => {
  return (
    <S.Address onClick={onClick}>
      <div style={{ display: "flex" }}>
        <S.AddressImage>
          <img src="/img/pin-bag.svg" />{" "}
        </S.AddressImage>
        {isBentoPackage && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0 24px",
              fontSize: "16px",
              fontFamily: "Nunito",
              fontWeight: "700",
            }}
          >
            <strong>{label}</strong>
          </div>
        )}
        <p style={{ textAlign: "left" }}>
          <strong>{address.description}</strong>
          <br />
          {address.streetNumber} {address.streetName}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <S.RightIcon src="/img/icons-right.svg" />
      </div>
    </S.Address>
  );
};

export default AddressItem;
