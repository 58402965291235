import CardList from "components/CardList";
import { CardType } from "components/CardList/interface";
import * as C from "components/Cart/styles";
import { Dispatch, SetStateAction, useState } from "react";
import ContentLoader from "react-content-loader";
import * as S from "./styles";

type CardListProps = {
  items: CardType[];
  handleCloseCards: Dispatch<SetStateAction<boolean>>;
  handleOpenNewCard: Dispatch<SetStateAction<boolean>>;
  onSetPayment: (card: CardType | null) => void;
};

const MyLoader = () => (
  <ContentLoader
    height={110}
    speed={1}
    backgroundColor={"#616161"}
    foregroundColor={"#b4b4b4"}
    viewBox="0 0 280 40"
  >
    <rect x="0" y="8" rx="4" ry="4" width="100%" height="25" />
  </ContentLoader>
);

const CardListCart = ({
  items,
  handleCloseCards,
  handleOpenNewCard,
  onSetPayment,
}: CardListProps) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <C.CartContent>
      <C.MobileMerchantHeader>
        <h2>Payment Method</h2>

        <C.MobileCloseButton onClick={() => handleCloseCards(false)}>
          <img src="/img/icons-close.svg" />
        </C.MobileCloseButton>
      </C.MobileMerchantHeader>

      <C.OverflowContent>
        <C.Section noBorderBottom>
          <S.ListTitle>Payment Method</S.ListTitle>

          <CardList
            items={items}
            handleCloseCards={handleCloseCards}
            onSetPayment={onSetPayment}
            onLoading={setIsLoading}
          />
        </C.Section>
      </C.OverflowContent>

      <C.ButtonWrapper>
        <S.ButtonNew
          onClick={() => handleOpenNewCard(true)}
          disabled={isLoading}
        >
          Add new card
        </S.ButtonNew>
      </C.ButtonWrapper>
    </C.CartContent>
  );
};

export default CardListCart;
