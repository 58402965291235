import { format } from "date-fns";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import ContentLoader from "react-content-loader";
import ReactTooltip from "react-tooltip";

import Empty from "components/Empty";
import useCart from "hooks/useCart";
import { MagicVoucherType } from "./interface";

import * as S from "./styles";
import { useAuth } from "contexts/AuthUserContext";
import Button from "components/Button";
import api from "services/api";
import { useMediaQuery } from "react-responsive";
import { Modal } from "components/Modal";
import MagicVoucherDetailModal from "components/MagicVoucherDetailModal";
import useModal from "hooks/useModal";

const MyLoader = () => (
  <ContentLoader
    height={110}
    speed={1}
    foregroundColor={"#e6e6e6"}
    viewBox="0 0 280 40"
  >
    <rect x="0" y="8" rx="4" ry="4" width="100%" height="25" />
  </ContentLoader>
);

type MagicVouchersListProps = {
  cursor?: "pointer" | "default";
  isCart?: boolean;
  closeVouchersMenu?: () => void;
  onSetVoucher?: (voucher: MagicVoucherType) => void;
};

const MagicVouchersList = ({
  cursor = "pointer",
  isCart = false,
  onSetVoucher,
  closeVouchersMenu,
}: MagicVouchersListProps) => {
  const { vouchers, isVouchersLoading, cart, fetchVouchers } = useCart();
  const [currentVoucher, setCurrentVoucher] = useState<MagicVoucherType>();
  const { authUser, isLoadingAuthUser } = useAuth();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { open, toggle } = useModal();
  const [canShare, setCanShare] = useState(false);

  useEffect(() => {
    setCanShare(Boolean(navigator.canShare));
  }, []);

  const renderMagicVoucherDetailModal = () => {
    return (
      <Modal
        noHeader
        open={open}
        onClose={() => {
          toggle(false);
        }}
        widthAuto={!isMobile}
      >
        <MagicVoucherDetailModal
          voucher={currentVoucher}
          canShare={canShare}
          onShare={handleShare}
          onClose={() => {
            toggle(false);
          }}
        />
      </Modal>
    );
  };

  useEffect(() => {
    if (authUser) fetchVouchers();
  }, [authUser]);

  const alreadyAddedVoucher = useCallback(
    (id: string) => {
      return (
        isCart && cart.magicVouchers.some((voucher) => voucher.voucherId === id)
      );
    },
    [cart]
  );

  const handleShare = async (voucher: any) => {
    if (canShare) {
      const { data: voucherShare } = await api.post(`/vouchers/share`, {
        voucherDocId: voucher.id,
      });

      const dataToShare = {
        url: voucherShare.link,
        title: voucherShare.title,
        text: voucherShare.message,
      };

      await navigator.share(dataToShare);
    }
  };

  return (
    <>
      <S.VoucherList>
        {isVouchersLoading ? (
          <MyLoader />
        ) : vouchers.length === 0 ? (
          <S.Box>
            <Empty
              image="/img/icons-message-empty-state.svg"
              description="You don’t have any registered vouchers yet."
            />
          </S.Box>
        ) : (
          vouchers.map((voucher) => (
            <S.VoucherItem key={voucher.voucherId} isCart={isCart}>
              <S.WrapperButton
                onClick={() => {
                  if (!alreadyAddedVoucher(voucher.voucherId)) {
                    closeVouchersMenu && closeVouchersMenu();
                    onSetVoucher && onSetVoucher(voucher);
                  }
                  if (!isCart) {
                    setCurrentVoucher(voucher);
                    toggle(true);
                  }
                }}
                cursor={
                  alreadyAddedVoucher(voucher.voucherId) ? "default" : cursor
                }
                added={alreadyAddedVoucher(voucher.voucherId)}
                isCart={isCart}
                data-tip={alreadyAddedVoucher(voucher.voucherId) ? "Added" : ""}
              >
                <S.VoucherItemImage bg={voucher.image} />

                <S.VoucherInfo>
                  <S.VoucherItemTitle>{voucher.title}</S.VoucherItemTitle>
                  <S.VoucherItemInfo>
                    Magic Voucher: {voucher.voucherId}
                  </S.VoucherItemInfo>
                  <S.VoucherItemExpireDate>
                    Expires on{" "}
                    {format(new Date(voucher.expiryDate), "MMM do, yyyy")}
                  </S.VoucherItemExpireDate>

                  {canShare && (
                    <Button
                      size="medium"
                      fullWidth
                      onClick={(e: any) => {
                        e.stopPropagation();
                        handleShare(voucher);
                      }}
                    >
                      Share
                    </Button>
                  )}
                </S.VoucherInfo>
              </S.WrapperButton>

              <ReactTooltip
                effect="solid"
                className="tooltip"
                backgroundColor="#433d3d"
                borderColor="#433d3d"
              />
            </S.VoucherItem>
          ))
        )}
      </S.VoucherList>

      {open && renderMagicVoucherDetailModal()}
    </>
  );
};

export default MagicVouchersList;
