import { useAuth } from "contexts/AuthUserContext";
import { Analytics, getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  isSupported,
  Messaging,
} from "firebase/messaging";
import { useEffect } from "react";
import api from "services/api";

const MessagingContainer = () => {
  const { authUser } = useAuth();

  const firebaseConfig = {
    apiKey: process.env.NEXT_PUBLIC_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_DATABASE_URL,
    projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
  };

  let firebase = null;
  let analytics: Analytics | null = null;
  let messaging: Messaging | null = null;

  useEffect(() => {
    function isSupportedBrowser(): boolean {
      let supported = false;

      isSupported().then((sup) => {
        supported = sup;
      });

      return supported;
    }

    firebase = initializeApp(firebaseConfig);
    analytics = getAnalytics(firebase);
    messaging = isSupportedBrowser() ? getMessaging(firebase) : null;

    if (authUser && messaging) {
      getToken(messaging, {
        vapidKey:
          "BOSvCZCJue-SWqUvf5BdBzd75--9TOQO5YlTusEdu3Totopa9mB0GfSfOAnN7yREOLfgH86iXOLJnaq7paFD7yk",
      })
        .then((currentToken) => {
          if (currentToken) {
            try {
              api.patch(`/users/${authUser?.uid}/token`, {
                token: currentToken,
              });
            } finally {
            }
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    }
  }, []);

  return <></>;
};

export default MessagingContainer;
